import React from 'react'

import { Stack, useMediaQuery } from '@mui/material'
import { t } from 'i18next'
import { Outlet } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { GlobalError, Loader, ReactiveDialog } from 'components'
import { useMe } from 'hooks/user'
import { usePreferences } from '@backoffice/hooks/preferrences'
import theme from './index.theme'
import { Header } from '@backoffice/components/common'

const BackofficeLayout = () => {
    const { error } = usePreferences()

    const lessThan700px = useMediaQuery('(max-width:700px)')

    const { loading } = useMe()

    if (loading) {
        return <Loader />
    }

    if (error) {
        const isRestricted = error.message === 'Only owner can request data'
        return (
            <GlobalError
                text={
                    isRestricted
                        ? (t('common:errors.pageRestricted') as string)
                        : (t('common:errors.somethingWentWrong') as string)
                }
            />
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ReactiveDialog />
            <Stack
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: 'max(100%, 100vh)',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    padding: lessThan700px
                        ? '20px 10px 50px'
                        : '25px 25px 50px',
                    background:
                        'radial-gradient(circle, rgba(47,47,47,1) 0%, rgba(24,24,24,1) 90%)',
                    minHeight: '100vh',
                }}
            >
                <Header />
                <Outlet />
            </Stack>
        </ThemeProvider>
    )
}

export default BackofficeLayout

import React from 'react'

import {
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material'
import {
    HomeOutlined,
    Notifications,
    NotificationsNone,
    SettingsOutlined,
    StorefrontOutlined,
} from '@mui/icons-material'
import { t } from 'i18next'
import { useMatch, useNavigate } from 'react-router-dom'
import { keyframes } from '@emotion/react'

import { paths as storefrontPaths } from '@storefront/index.routes'
import { paths as backofficePaths } from '@backoffice/index.routes'
import { Logo } from 'components'
import { useMe } from 'hooks/user'

const shakeAnimation = keyframes`
  0% {
    transform: rotate(0deg)
  }
  10% {
    transform: rotate(-15deg);
    background-color: rgba(214, 191, 98, 0.07);
  }
  20% {
    transform: rotate(15deg);
    background-color: rgba(214, 191, 98, 0.17);
  }
  30% {
    transform: rotate(-15deg);
    background-color: rgba(214, 191, 98, 0.27);
  }
  40% {
    transform: rotate(15deg);
    background-color: rgba(214, 191, 98, 0.37);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

const Header = () => {
    const navigate = useNavigate()
    const isDashboard = !!useMatch('/backoffice')

    const lessThan800px = useMediaQuery('(max-width:800px)')
    const lessThan700px = useMediaQuery('(max-width:700px)')

    const { me, loading, role } = useMe()

    const hasNotifications = false

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <Logo
                noColor
                onClick={() => navigate(`../${backofficePaths.backoffice}`)}
                height={lessThan700px ? '34px' : '52px'}
            />
            <Stack
                gap={lessThan700px ? '10px' : '25px'}
                direction="row"
                alignItems="center"
                divider={<Divider flexItem orientation="vertical" />}
            >
                {!lessThan800px && (
                    <Typography fontSize="max(min(2vw, 17px), 14px)">
                        {me?.email}
                    </Typography>
                )}
                <IconButton
                    disabled
                    onClick={() => navigate(backofficePaths.notifications)}
                    sx={
                        hasNotifications
                            ? {
                                  animation: `${shakeAnimation} 3s ease-in-out infinite`,
                              }
                            : {}
                    }
                >
                    {hasNotifications ? (
                        <Notifications
                            sx={{
                                color: '#d6bf62',
                            }}
                        />
                    ) : (
                        <NotificationsNone />
                    )}
                </IconButton>
                <IconButton
                    disabled
                    onClick={() => navigate(backofficePaths.settings)}
                >
                    <SettingsOutlined />
                </IconButton>

                {!isDashboard && (
                    <IconButton
                        onClick={() =>
                            navigate(`../${backofficePaths.backoffice}`)
                        }
                    >
                        <HomeOutlined />
                    </IconButton>
                )}
                <Tooltip
                    title={
                        <Typography fontSize="18px">
                            {t('backoffice:toStorefront')}
                        </Typography>
                    }
                >
                    <IconButton
                        onClick={() => navigate(`../${storefrontPaths.expert}`)}
                    >
                        <StorefrontOutlined
                            sx={{
                                fontSize: '35px',
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Stack>
    )
}

export default Header

import React, { Suspense } from 'react'

import { Box } from '@mui/material'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Loader } from 'components'
import { useMe } from 'hooks/user'
import AuthLayout from '@auth/index.layout'
import BackofficeLayout from '@backoffice/index.layout'
import StorefrontLayout from '@storefront/index.layout'
import { routes as backofficeRoutes } from '@backoffice/index.routes'
import { routes as storefrontRoutes } from '@storefront/index.routes'
import { routes as authRoutes } from '@auth/index.routes'
import { ROLES } from 'config/user.config'
import { useCoachProfile } from '@storefront/hooks/coach'
import { UserType } from 'api/root/generated'

const App = () => {
    const { loading, role } = useMe()
    const { user, loading: coachLoading } = useCoachProfile()
    if (loading || coachLoading) {
        return <Loader />
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'stretch',
                '@media (max-width:550px)': {
                    height: 'auto',
                },
            }}
        >
            <Suspense fallback={<Loader />}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<StorefrontLayout />}>
                            {storefrontRoutes.map(r => (
                                <Route
                                    key={r.path}
                                    path={r.path}
                                    element={r.element}
                                />
                            ))}
                        </Route>
                        <Route
                            path="/authorization"
                            element={<AuthLayout user={user as UserType} />}
                        >
                            {authRoutes.map(r => (
                                <Route
                                    key={r.path}
                                    path={r.path}
                                    element={r.element}
                                />
                            ))}
                        </Route>
                        {role === ROLES.COACH && (
                            <Route
                                path="/backoffice"
                                element={<BackofficeLayout />}
                            >
                                {backofficeRoutes.map(r => (
                                    <Route
                                        key={r.path}
                                        path={r.path}
                                        element={r.element}
                                    />
                                ))}
                            </Route>
                        )}
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </Box>
    )
}

export default App

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import storefrontDictionaryUA from '@storefront/dictionary/ua.json'
import backofficeDictionaryUA from '@backoffice/dictionary/ua.json'
import authDictionaryUA from '@auth/dictionary/ua.json'
import commonDictionaryUA from 'dictionary/ua.json'

export default function () {
    // eslint-disable-next-line no-underscore-dangle
    window.__localeId__ = 'ua'
    i18n.use(initReactI18next).init({
        resources: {
            ua: {
                storefront: storefrontDictionaryUA,
                backoffice: backofficeDictionaryUA,
                auth: authDictionaryUA,
                common: commonDictionaryUA,
            },
        },
        lng: 'ua',
        ns: ['storefront', 'backoffice', 'auth', 'common'],
        defaultNS: 'common',
        interpolation: {
            escapeValue: false,
        },
    })
}

import React, { useState } from 'react'

import { Visibility, VisibilityOff, Key } from '@mui/icons-material'
import {
    IconButton,
    InputAdornment,
    TextFieldProps,
    TextField,
} from '@mui/material'

const PasswordField = (props: TextFieldProps) => {
    const [visible, setVisible] = useState(false)

    return (
        <TextField
            type={visible ? 'text' : 'password'}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Key />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setVisible(prevState => !prevState)}
                            sx={theme => ({
                                '& .MuiSvgIcon-root': {
                                    color: theme.palette.secondary.main,
                                },
                            })}
                        >
                            {visible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    )
}

export default PasswordField

import React, { JSX } from 'react'

import { Divider, Stack, useMediaQuery } from '@mui/material'
import { t } from 'i18next'

import { useDocumentTitle } from '../../../../../hooks/common'

const FeedbackPage = (): JSX.Element => {
    useDocumentTitle(t('backoffice:pageTitles.feedbackPage'))

    const lessThan1440px = useMediaQuery('(max-width:1440px)')
    const lessThan700px = useMediaQuery('(max-width:700px)')

    return (
        <Stack
            divider={<Divider flexItem orientation="vertical" />}
            direction="row"
            gap={lessThan1440px ? '25px' : '50px'}
            justifyContent="space-between"
            padding={lessThan700px ? '25px 5px' : '50px 35px'}
        >
            <h1>dsq</h1>
        </Stack>
    )
}
export default FeedbackPage

import React, { useRef } from 'react'

import { Box, Checkbox, Stack, Typography, useMediaQuery } from '@mui/material'
import { QuestionMarkOutlined } from '@mui/icons-material'

import { Image } from 'components'
import { LessonType } from '../../../../../../api/root/generated'
import checkbox from 'assets/images/checkbox.svg'
import checkboxChecked from 'assets/images/checkbox-checked.svg'

interface LessonProps {
    data: LessonType
    onSelect: (lesson: LessonType, selected: boolean) => void
    selected?: boolean
}

const Lesson = ({ data, onSelect, selected }: LessonProps) => {
    const lessThan610px = useMediaQuery('(max-width:610px)')

    const checkboxRef = useRef<HTMLInputElement | null>(null)

    return (
        <Stack
            onClick={() => {
                if (checkboxRef.current) {
                    checkboxRef.current.click()
                }
            }}
            gap={0}
            sx={theme => ({
                boxShadow: '0 0 7px #000',
                border: `2px solid transparent`,
                cursor: 'pointer',
                position: 'relative',
                width: lessThan610px ? '100%' : '240px',
                '&:hover': {
                    border: `2px solid ${theme.palette.primary.main}`,
                },
            })}
        >
            <Checkbox
                inputRef={checkboxRef}
                icon={<Image src={checkbox} />}
                checkedIcon={<Image src={checkboxChecked} />}
                checked={selected}
                onChange={(_, checked) => {
                    onSelect?.(data, checked)
                }}
                onClick={e => e.stopPropagation()}
                sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    zIndex: 3,
                    transform: 'translate(-50%, -40%)',
                    padding: '5px',
                    '& > img': {
                        width: '78px',
                        height: '78px',
                    },
                }}
            />
            <Box
                sx={{
                    background: 'rgba(0, 0, 0, .5)',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            />
            {data.videoPreview ? (
                <Image
                    src={data.videoPreview.img520x200 as string}
                    sx={{
                        width: '100%',
                        height: '160px',
                        objectFit: 'cover',
                    }}
                    alt=""
                />
            ) : (
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    height="160px"
                >
                    <QuestionMarkOutlined
                        sx={{
                            color: '#4e4e4e',
                            fontSize: '105px',
                        }}
                    />
                </Stack>
            )}
            <Typography
                noWrap
                textAlign="right"
                sx={{
                    position: 'relative',
                    zIndex: 2,
                    borderTop: '1px solid #2e2e2e',
                    padding: '10px',
                    maxWidth: '100%',
                }}
            >
                {data.name}
            </Typography>
        </Stack>
    )
}
export default Lesson

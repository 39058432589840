import React, { JSX } from 'react'

import { Typography, Stack, TextField, Button } from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { number, object, string } from 'yup'

import { Image } from 'components'
import monobankLogo from 'assets/images/monobank.png'

export interface MonobankFormValues {
    paymentService: number
    payload: {
        token: string
    }
}

interface MonobankFormProps {
    paymentService: number
    onSubmit: (values: MonobankFormValues) => void
}

const MonobankForm = ({
    paymentService,
    onSubmit,
}: MonobankFormProps): JSX.Element => {
    const formik = useFormik({
        initialValues: {
            paymentService,
            payload: {
                token: '',
            },
        },
        validationSchema: object({
            paymentService: number(),
            payload: object({
                token: string().required(
                    t('auth:errors.required-field') as string
                ),
            }),
        }),
        onSubmit,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack
                gap="25px"
                padding="25px"
                sx={{
                    width: '375px',
                }}
            >
                <Typography fontSize="22px">
                    {t('backoffice:settings.payment.forms.monobankTitle')}
                </Typography>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        boxShadow: '0 0 7px #000',
                        border: `2px solid #000`,
                        padding: '20px 10px',
                        background: '#e5e5e5',
                        position: 'relative',
                        marginLeft: '-24px',
                        marginRight: '-24px',
                    }}
                >
                    <Image
                        src={monobankLogo}
                        sx={{
                            width: '320px',
                        }}
                    />
                </Stack>
                <Stack gap="25px">
                    <TextField
                        placeholder={
                            t(
                                'backoffice:settings.payment.forms.monobankTokenLabel'
                            ) as string
                        }
                        variant="outlined"
                        fullWidth
                        name="payload.token"
                        value={formik.values.payload.token}
                        onChange={formik.handleChange}
                        error={
                            formik.touched?.payload?.token &&
                            Boolean(formik.errors?.payload?.token)
                        }
                        helperText={
                            formik.touched?.payload?.token &&
                            formik.errors?.payload?.token
                        }
                    />
                </Stack>
                <Stack gap="10px">
                    <LoadingButton variant="contained" type="submit">
                        {t('backoffice:settings.payment.forms.save')}
                    </LoadingButton>
                    <Button variant="outlined">
                        {t('backoffice:settings.payment.forms.cancel')}
                    </Button>
                </Stack>
            </Stack>
        </form>
    )
}
export default MonobankForm

import {
    ApolloClient,
    from,
    fromPromise,
    InMemoryCache,
    Operation,
    NextLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link'

import { RefreshTokenDocument } from 'api/auth/generated'
import { PathStorage } from 'services'
import { paths } from '@auth/index.routes'

enum AuthenticationMessages {
    ACCESS_TOKEN_EXPIRED = 'You do not have permission to perform this action',
    ACCESS_TOKEN_INVALID = 'Signature has expired',
}

const refreshToken = () => {
    const authClient = new ApolloClient({
        uri: PathStorage.auth(),
        cache: new InMemoryCache(),
        credentials: 'include',
    })

    return fromPromise(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        authClient
            .mutate({
                mutation: RefreshTokenDocument,
            })
            .catch(e => {
                localStorage.removeItem('authenticated')

                const currentPage = window.location.href

                if (
                    currentPage.includes('backoffice') ||
                    currentPage.includes('watch') ||
                    currentPage.includes('profile')
                ) {
                    window.location.replace(
                        `${window.location.origin}/${paths.login}?redirectTo=${currentPage}&expired=true`
                    )
                } else {
                    window.location.replace(window.location.href)
                }
            })
    )
}

const AuthenticationLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        const messages = graphQLErrors.map(({ message }) => message)

        const tokenShouldBeRefreshed =
            messages.includes(AuthenticationMessages.ACCESS_TOKEN_INVALID) ||
            messages.includes(AuthenticationMessages.ACCESS_TOKEN_EXPIRED)

        if (tokenShouldBeRefreshed) {
            return refreshToken()
                .filter(value => Boolean(value))
                .flatMap(() => forward(operation))
        }

        return forward(operation)
    }

    return forward(operation)
})

const multiHttpLink = new MultiAPILink({
    endpoints: {
        root: PathStorage.root(),
        payment: PathStorage.payment(),
        auth: PathStorage.auth(),
        feedback: PathStorage.feedback(),
    },
    defaultEndpoint: 'root',
    httpSuffix: '',
    createHttpLink: () =>
        createUploadLink({
            credentials: 'include',
        }),
})

const client = new ApolloClient({
    cache: new InMemoryCache({
        resultCaching: true,
        addTypename: true,
        typePolicies: {
            UserType: {
                fields: {
                    background: {
                        merge: true,
                    },
                },
            },
            CourseType: {
                fields: {
                    background: {
                        merge: true,
                    },
                },
            },
            LessonType: {
                fields: {
                    videoPreview: {
                        merge: true,
                    },
                },
            },
        },
    }),
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: from([AuthenticationLink.concat(multiHttpLink)]),
})

export { client }

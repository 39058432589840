import { useMutation, FetchResult } from '@apollo/client'

import {
    EmailSignInDocument,
    EmailSignInMutation,
    MutationEmailSignInArgs,
    ResetPasswordDocument,
    ResetPasswordMutation,
    ResetPasswordInput,
    ResetPasswordConfirmDocument,
    ResetPasswordConfirmMutation,
    ResetPasswordConfirmInput,
    ActivateAccountDocument,
    ActivateAccountMutation,
    MutationActivateAccountArgs,
    CheckEmailTokenLinkDocument,
    CheckEmailTokenLinkInput,
    CheckEmailTokenLinkMutation,
    EmailConfirmDocument,
    EmailConfirmInput,
    EmailConfirmMutation,
    LogoutDocument,
    LogoutMutationVariables,
    UserType,
} from 'api/auth/generated'

export const useAuth = () => {
    const [emailConfirm, { loading: confirmEmailIsProcessing }] =
        useMutation(EmailConfirmDocument)
    const [checkEmailToken, { loading: checkEmailTokenIsProcessing }] =
        useMutation(CheckEmailTokenLinkDocument)
    const [signInApi, { loading: loginIsProcessing }] =
        useMutation(EmailSignInDocument)
    const [activate] = useMutation(ActivateAccountDocument)
    const [logoutUser] = useMutation(LogoutDocument)

    const [resetPasswordApi, { loading: resetPasswordLoading }] = useMutation(
        ResetPasswordDocument
    )

    const [resetPasswordConfirmApi, { loading: resetPasswordConfirmLoading }] =
        useMutation(ResetPasswordConfirmDocument)

    const login = (
        data: MutationEmailSignInArgs,
        onSuccess?: (user: UserType) => void
    ) => {
        return signInApi({ variables: data }).then(
            (response: FetchResult<EmailSignInMutation>) => {
                const result = response?.data?.emailSignIn
                if (result?.success) {
                    if (onSuccess) {
                        localStorage.setItem('authenticated', 'true')
                        onSuccess(result.user as UserType)
                    }
                }
                return result as FetchResult<EmailSignInMutation>
            }
        )
    }

    const logout = () => {
        return logoutUser().then(() => {
            localStorage.removeItem('authenticated')
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.location = window.location.href
        })
    }

    const verifyEmailToken = (data: CheckEmailTokenLinkInput) => {
        return checkEmailToken({ variables: { input: data } }).then(
            (response: FetchResult<CheckEmailTokenLinkMutation>) =>
                response?.data?.checkEmailTokenLink
        )
    }

    const confirmEmail = (data: EmailConfirmInput) => {
        return emailConfirm({ variables: { input: data } }).then(
            (response: FetchResult<EmailConfirmMutation>) =>
                response?.data?.emailConfirm
        )
    }

    const activateAccount = (data: MutationActivateAccountArgs) => {
        return activate({ variables: data }).then(
            (response: FetchResult<ActivateAccountMutation>) =>
                response?.data?.activateAccount
        )
    }

    const resetPassword = (data: ResetPasswordInput) => {
        return resetPasswordApi({ variables: { input: data } }).then(
            (response: FetchResult<ResetPasswordMutation>) =>
                response?.data?.resetPassword
        )
    }

    const resetPasswordConfirm = (data: ResetPasswordConfirmInput) => {
        return resetPasswordConfirmApi({ variables: { input: data } }).then(
            (response: FetchResult<ResetPasswordConfirmMutation>) =>
                response?.data?.resetPasswordConfirm
        )
    }

    return {
        login,
        logout,
        resetPassword,
        resetPasswordConfirm,
        activateAccount,
        verifyEmailToken,
        confirmEmail,
        isLoading:
            resetPasswordLoading ||
            resetPasswordConfirmLoading ||
            loginIsProcessing ||
            checkEmailTokenIsProcessing,
    }
}

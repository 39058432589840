import React, { useState, JSX } from 'react'

import { Typography, Stack, Divider, TextField } from '@mui/material'
import { t } from 'i18next'

import Layout from './Layout'
import { UserType } from '../../../../../../api/root/generated'

interface AccountProps {
    user: UserType
}

const Socials = ({ user }: AccountProps): JSX.Element => {
    const [instagram, setInstagram] = useState('')
    const [facebook, setFacebook] = useState('')
    const [tiktok, setTiktok] = useState('')

    // isURL(url, {
    //     require_protocol: true,
    //     require_host: false,
    //     require_tld: false,
    // })

    return (
        <Layout title={t('backoffice:settings.socials.title')}>
            <Stack gap="15px" divider={<Divider />}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography fontSize="22px">
                        {t('backoffice:settings.socials.instagramLabel')}
                    </Typography>
                    <TextField
                        placeholder="URL"
                        variant="outlined"
                        sx={{
                            width: '300px',
                        }}
                        value={instagram}
                        onChange={e => setInstagram(e.target.value)}
                    />
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography fontSize="22px">
                        {t('backoffice:settings.socials.tiktokLabel')}
                    </Typography>
                    <TextField
                        placeholder="URL"
                        variant="outlined"
                        sx={{
                            width: '300px',
                        }}
                        value={tiktok}
                        onChange={e => setTiktok(e.target.value)}
                    />
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography fontSize="22px">
                        {t('backoffice:settings.socials.facebookLabel')}
                    </Typography>
                    <TextField
                        placeholder="URL"
                        variant="outlined"
                        sx={{
                            width: '300px',
                        }}
                        value={facebook}
                        onChange={e => setFacebook(e.target.value)}
                    />
                </Stack>
            </Stack>
        </Layout>
    )
}
export default Socials

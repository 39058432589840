import React, { useCallback, useEffect, useState } from 'react'

import {
    Box,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'
import {
    AddCircleOutline,
    DeleteOutline,
    DragIndicatorOutlined,
    EditOutlined,
    MoreVert,
} from '@mui/icons-material'

import { CourseType, LessonType, ModuleType } from 'api/root/generated'
import Lesson from './Lesson'

interface ModuleProps {
    module: ModuleType
    reordering?: boolean
    onNewLesson: (module: ModuleType, lesson?: LessonType) => void
    onEditLesson: (module: ModuleType, lesson: LessonType) => void
    onDeleteLesson: (lesson: LessonType) => void
    onOpenLesson: (lesson: LessonType) => void
    onEditModule: (course: CourseType, module: ModuleType) => void
    onDeleteModule: (module: ModuleType) => void
}

interface Item {
    id: string
    name: string
    videoPreviewUrl: string
}

const Module = ({
    module,
    onNewLesson,
    onEditModule,
    onDeleteModule,
    onDeleteLesson,
    onOpenLesson,
    onEditLesson,
    reordering = false,
}: ModuleProps) => {
    const lessThan1000px = useMediaQuery('(max-width:1000px)')
    const lessThan700px = useMediaQuery('(max-width:700px)')

    const [cards, setCards] = useState<Item[]>([])
    const [anchor, setAnchor] = useState<HTMLElement | null>(null)

    useEffect(() => {
        setCards(
            module.lessons.edges
                .map(e => e?.node)
                .map(l => ({
                    id: l?.id as string,
                    name: l?.name as string,
                    videoPreviewUrl: l?.videoPreview?.img520x200 as string,
                }))
        )
    }, [module])

    const renderLesson = useCallback(
        (card: Item) => {
            const lesson = module.lessons.edges
                .map(n => n?.node)
                .find(l => l?.id === card.id)
            return (
                <Lesson
                    data={lesson as LessonType}
                    onDelete={onDeleteLesson}
                    onEdit={onEditLesson}
                    onOpen={onOpenLesson}
                    key={card.id}
                    id={card.id}
                    name={card.name}
                    videoPreviewUrl={card.videoPreviewUrl}
                />
            )
        },
        [module, cards]
    )

    const renderActions = () => {
        if (lessThan700px) {
            return (
                <Stack>
                    <IconButton
                        onClick={e => setAnchor(e.currentTarget)}
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, .8)',
                            },
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        open={!!anchor}
                        anchorEl={anchor}
                        onClose={() => setAnchor(null)}
                    >
                        <MenuItem
                            sx={{
                                gap: '10px',
                            }}
                            onClick={() => {
                                onEditModule(module.course, module)
                                setAnchor(null)
                            }}
                        >
                            <EditOutlined />
                            <Typography>
                                {t(
                                    'backoffice:content.course.module.actions.edit'
                                )}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            sx={{
                                gap: '10px',
                            }}
                            onClick={() => {
                                onDeleteModule(module)
                                setAnchor(null)
                            }}
                        >
                            <DeleteOutline />
                            <Typography>
                                {t(
                                    'backoffice:content.course.module.actions.delete'
                                )}
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            sx={{
                                gap: '10px',
                            }}
                            onClick={() => {
                                onNewLesson(module)
                                setAnchor(null)
                            }}
                        >
                            <AddCircleOutline color="primary" />
                            <Typography>
                                {t(
                                    'backoffice:content.course.lesson.actions.create'
                                )}
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Stack>
            )
        }
        return (
            <Stack
                justifyContent={lessThan1000px ? 'flex-end' : 'space-between'}
                alignItems="center"
                direction="row"
            >
                <Stack alignItems="center" direction="row" gap="10px">
                    <Tooltip
                        title={
                            <Typography fontSize="18px" variant="caption">
                                {t(
                                    'backoffice:content.course.module.actions.edit'
                                )}
                            </Typography>
                        }
                    >
                        <IconButton
                            onClick={() => onEditModule(module.course, module)}
                        >
                            <EditOutlined />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={
                            <Typography fontSize="18px" variant="caption">
                                {t(
                                    'backoffice:content.course.module.actions.delete'
                                )}
                            </Typography>
                        }
                    >
                        <IconButton onClick={() => onDeleteModule(module)}>
                            <DeleteOutline />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title={
                            <Typography fontSize="18px" variant="caption">
                                {t(
                                    'backoffice:content.course.lesson.actions.create'
                                )}
                            </Typography>
                        }
                    >
                        <IconButton
                            color="primary"
                            onClick={() => {
                                onNewLesson(module)
                            }}
                        >
                            <AddCircleOutline
                                sx={{
                                    fontSize: '26px',
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack key={module.id}>
            <Stack
                sx={{
                    padding: '15px 25px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
                    border: '1px solid #222',
                    background: 'rgba(0, 0, 0, 0.48)',
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack
                    alignItems="center"
                    gap={lessThan1000px ? '0' : '25px'}
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                >
                    {reordering && <DragIndicatorOutlined />}
                    <Stack
                        direction="row"
                        gap="15px"
                        divider={<Divider flexItem orientation="vertical" />}
                        alignItems="center"
                    >
                        <Typography
                            noWrap
                            sx={{
                                fontSize: 'max(min(1vw, 32px), 14px)',
                                width: 'fit-content',
                                minWidth: 'fit-content',
                            }}
                        >
                            {module.name}
                        </Typography>
                        {!lessThan1000px && (
                            <Typography
                                sx={{
                                    flexGrow: 1,
                                    fontSize: 'max(min(1vw, 13px), 12px)',
                                    color: '#cfcfcf',
                                }}
                            >
                                {module.description}
                            </Typography>
                        )}
                    </Stack>
                    {renderActions()}
                </Stack>
            </Stack>
            <Box
                sx={{
                    padding: '25px',
                    width: '100%',
                }}
            >
                <Stack
                    sx={{
                        flexWrap: 'wrap',
                        '@media (max-width:480px)': {
                            flexWrap: 'nowrap',
                            flexDirection: 'column',
                        },
                    }}
                    direction="row"
                    gap="35px"
                >
                    {cards.map(renderLesson)}
                </Stack>
            </Box>
        </Stack>
    )
}
export default Module

import React, { useState } from 'react'

import { useFormik } from 'formik'
import { Box, TextField, Stack, Typography, Button } from '@mui/material'
import { t } from 'i18next'
import { PanoramaOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

import { FilePicker } from 'components'
import { CourseFormScheme, CourseFormValues } from './index.schema'
import { CourseType } from 'api/root/generated'
import { useCoursesAPI } from 'hooks/courses'

interface CourseFormProps {
    onSuccess: (action: 'add' | 'update', id?: string) => void
    onDelete: () => void
    course?: Partial<CourseType>
}

const CourseForm = ({ onSuccess, course, onDelete }: CourseFormProps) => {
    const [background, setBackground] = useState<File | null>(null)

    const { create, update, loading } = useCoursesAPI()

    const formik = useFormik({
        initialValues: course
            ? {
                  name: course.name || '',
                  description: course.description || '',
              }
            : {
                  name: '',
                  description: '',
              },
        validationSchema: CourseFormScheme,
        onSubmit: (values: CourseFormValues) => {
            const { name, description } = values
            if (course) {
                update({
                    input: {
                        courseId: course.id as string,
                        name,
                        description,
                        ...(background && { background }),
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.('update')
                    }
                })
            } else {
                create({
                    input: {
                        name,
                        description,
                        ...(background && { background }),
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.('add', response.course?.id as string)
                    }
                })
            }
        },
    })

    return (
        <Box
            sx={{
                padding: '25px',
                width: '100%',
                maxWidth: '500px',
                minWidth: '500px',
                '@media (max-width:550px)': {
                    padding: '25px',
                },
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="25px">
                    <Stack>
                        <Typography
                            fontSize="20px"
                            dangerouslySetInnerHTML={{
                                __html: course
                                    ? t('backoffice:content.course.editTitle')
                                    : t(
                                          'backoffice:content.course.createTitle'
                                      ),
                            }}
                        />
                        <Typography
                            sx={theme => ({
                                fontSize: '22px',
                                color: theme.palette.primary.main,
                            })}
                        >
                            {course ? course.name : ''}
                        </Typography>
                    </Stack>
                    <Stack gap="25px">
                        <TextField
                            variant="outlined"
                            name="name"
                            label={
                                t(
                                    'backoffice:content.course.fields.name'
                                ) as string
                            }
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                            }
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                        />
                        <TextField
                            variant="outlined"
                            name="description"
                            label={
                                t(
                                    'backoffice:content.course.fields.description'
                                ) as string
                            }
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            rows={14}
                            multiline
                            error={
                                formik.touched.description &&
                                Boolean(formik.errors.description)
                            }
                            helperText={
                                formik.touched.description &&
                                formik.errors.description
                            }
                        />
                        <Stack
                            gap="10px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <PanoramaOutlined />
                            <FilePicker
                                title={t(
                                    'backoffice:content.course.fields.background'
                                )}
                                value={
                                    background?.name ||
                                    course?.appearance?.background?.original
                                }
                                onChange={(file: File | null) =>
                                    setBackground(file)
                                }
                            />
                        </Stack>
                    </Stack>
                    <Stack gap="10px">
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            fullWidth
                            sx={{ padding: '15px' }}
                            loading={loading}
                        >
                            {course
                                ? t('backoffice:content.course.actions.save')
                                : t('backoffice:content.course.actions.create')}
                        </LoadingButton>
                        {course && (
                            <LoadingButton
                                loading={loading}
                                sx={{ padding: '10px' }}
                                color="error"
                                variant="text"
                                fullWidth
                                onClick={() => onDelete()}
                            >
                                {t('backoffice:content.course.actions.delete')}
                            </LoadingButton>
                        )}
                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}

export default CourseForm

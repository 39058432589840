import { object, string } from 'yup'

import { PASSWORD_REGEX } from '../index.validators'

export const ResetPasswordFormScheme = object({
    newPassword1: string()
        .matches(
            PASSWORD_REGEX,
            'Пароль має включати в себе: лiтери верхнього та нижнього регiстру, цифри та щонайменше один з символiв: @$!%*?&'
        )
        .required('Обов`язкове поле'),
    newPassword2: string()
        .test('password_same', 'Паролi мають бути однаковi', function (value) {
            const { newPassword1 } = this.parent

            return newPassword1 === value
        })
        .required('Обов`язкове поле'),
})

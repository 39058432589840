import React, { JSX } from 'react'

import { useFormik } from 'formik'
import {
    Box,
    Stack,
    Typography,
    TextField,
    Button,
    InputLabel,
    InputAdornment,
} from '@mui/material'
import { t } from 'i18next'
import { AlternateEmail } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

import { PasswordField, Form } from 'components'
import {
    LoginFormValues,
    LoginFormInitialValues,
    LoginFormScheme,
} from './index.schema'
import { useAuth } from '@auth/hooks/auth'
import { UserType } from 'api/auth/generated'

interface LoginFormProps {
    onSuccess?: (user: UserType) => void
    onForgotPassword: () => void
    tokenHasExpired?: boolean
}

const LoginForm = ({
    onForgotPassword,
    onSuccess,
    tokenHasExpired,
}: LoginFormProps): JSX.Element => {
    const { login, isLoading } = useAuth()

    const formik = useFormik({
        initialValues: LoginFormInitialValues,
        validationSchema: LoginFormScheme,
        onSubmit: (values: LoginFormValues) => {
            const { email, password } = values
            login(
                {
                    email: email.toLowerCase().trim(),
                    password,
                },
                onSuccess
            )
                .then(response => {
                    if (response?.errors?.[0]) {
                        formik.setErrors({
                            password: t('auth:errors.credentials') || '',
                        })
                    }
                })
                .catch(() => {
                    formik.setErrors({
                        password: t('auth:errors.credentials') || '',
                    })
                })
        },
    })

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Form
                onSubmit={formik.handleSubmit}
                sx={{
                    width: '100%',
                }}
            >
                <Stack gap="30px">
                    {tokenHasExpired ? (
                        <Typography
                            sx={{
                                fontSize: '24px',
                            }}
                            textAlign="center"
                        >
                            {t('auth:titles.tokenHasExpired')}
                        </Typography>
                    ) : (
                        <Typography
                            sx={{
                                fontSize: '24px',
                            }}
                            textAlign="center"
                        >
                            {t('auth:titles.clientAccountLoginTitle')}
                        </Typography>
                    )}

                    <Stack gap="40px" width="100%" justifyContent="center">
                        <Stack gap="8px">
                            <InputLabel htmlFor="email">
                                {t('auth:fields.email')}
                            </InputLabel>
                            <TextField
                                id="login-input"
                                fullWidth
                                autoComplete="off"
                                name="email"
                                variant="outlined"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                size="medium"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AlternateEmail />
                                        </InputAdornment>
                                    ),
                                }}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email && formik.errors.email
                                }
                            />
                        </Stack>
                        <Stack gap="8px">
                            <InputLabel htmlFor="email">
                                {t('auth:fields.password')}
                            </InputLabel>
                            <PasswordField
                                id="password-input"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                }
                                helperText={
                                    formik.touched.password &&
                                    formik.errors.password
                                }
                                fullWidth
                            />
                        </Stack>
                    </Stack>
                    <Stack gap="30px">
                        <Button
                            variant="text"
                            fullWidth
                            onClick={() => onForgotPassword()}
                            sx={{
                                padding: '4px !important',
                                justifyContent: 'flex-end',
                                fontSize: '15px',
                                fontWeight: 500,
                                textAlign: 'right',
                                color: '#fff',
                            }}
                        >
                            {t('auth:forgotPassword')}
                        </Button>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            fullWidth
                            loading={isLoading}
                            sx={{ padding: '15px', fontWeight: 700 }}
                        >
                            {t('auth:login')}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Form>
        </Box>
    )
}

export default LoginForm

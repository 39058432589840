import React, { JSX } from 'react'

import { useFormik } from 'formik'
import {
    Box,
    InputAdornment,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { t } from 'i18next'
import { AlternateEmail } from '@mui/icons-material'

import { PasswordField } from 'components'
import {
    ActivateAccountFormValues,
    ActivateAccountFormScheme,
} from './index.schema'
import { useAuth } from '@auth/hooks/auth'

interface LoginFormProps {
    email: string | null
    onSuccess: () => void
}

const ActivateAccountForm = ({
    email,
    onSuccess,
}: LoginFormProps): JSX.Element => {
    const { login, isLoading, activateAccount } = useAuth()
    const { t } = useTranslation()

    const formik = useFormik({
        initialValues: {
            email: email || '',
            password: '',
        },
        validationSchema: ActivateAccountFormScheme,
        onSubmit: (values: ActivateAccountFormValues) => {
            const { email, password } = values
            const normalizedEmail = email.toLowerCase().trim()
            activateAccount({
                input: {
                    email: normalizedEmail,
                    password,
                },
            }).then(response => {
                if (response?.success) {
                    login(
                        { email: normalizedEmail, password: values.password },
                        onSuccess
                    )
                }
            })
        },
    })

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="30px" width="100%">
                    <Typography
                        textAlign="center"
                        sx={{
                            fontSize: '24px',
                        }}
                    >
                        {t('auth:activateAccountTitle')}
                    </Typography>
                    <Stack gap="50px">
                        <Stack gap="8px">
                            <InputLabel htmlFor="email">
                                {t('auth:fields.email')}
                            </InputLabel>
                            <TextField
                                disabled
                                id="login-input"
                                fullWidth
                                autoComplete="off"
                                name="email"
                                variant="outlined"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                size="medium"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AlternateEmail />
                                        </InputAdornment>
                                    ),
                                }}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email && formik.errors.email
                                }
                            />
                        </Stack>
                        <Stack gap="8px">
                            <InputLabel htmlFor="email">
                                {t('auth:fields.activateAccountPassword')}
                            </InputLabel>
                            <PasswordField
                                id="password-input"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                }
                                helperText={
                                    formik.touched.password &&
                                    formik.errors.password
                                }
                                fullWidth
                            />
                        </Stack>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            fullWidth
                            loading={isLoading}
                            sx={{ padding: '15px' }}
                        >
                            {t('auth:activateAccountButton')}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}

export default ActivateAccountForm

import { object, string } from 'yup'

import { emailValidator, PASSWORD_REGEX } from '../index.validators'

export interface ActivateAccountFormValues {
    email: string
    password: string
}

export const ActivateAccountFormScheme = object({
    email: string().test(emailValidator).required('Обов`язкове поле'),
    password: string()
        .matches(
            PASSWORD_REGEX,
            'Пароль має включати в себе: лiтери верхнього та нижнього регiстру, цифри та щонайменше один з символiв: @$!%*?&'
        )
        .required('Обов`язкове поле'),
})

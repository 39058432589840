import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    PaymentMethodType,
    PaymentMethodListDocument,
    SetUpPaymentMethodDocument,
    SetUpPaymentMethodMutation,
    MutationSetUpPaymentMethodArgs,
} from 'api/payment/generated'

export const usePaymentAPI = () => {
    const [setUpPaymentMethod, { loading }] = useMutation(
        SetUpPaymentMethodDocument
    )

    const setPaymentMethod = (data: MutationSetUpPaymentMethodArgs) => {
        return setUpPaymentMethod({ variables: data }).then(
            (response: FetchResult<SetUpPaymentMethodMutation>) => {
                return response?.data?.setUpPaymentMethod
            }
        )
    }

    return {
        setPaymentMethod,
        loading,
    }
}

export const usePaymentMethods = () => {
    const [response, setResponse] = useState<PaymentMethodType[]>([])

    const { data, loading, error, refetch } = useQuery(
        PaymentMethodListDocument
    )

    useEffect(() => {
        if (data?.paymentMethodList) {
            const methods: PaymentMethodType[] =
                data?.paymentMethodList?.edges.map(
                    edge => edge?.node as PaymentMethodType
                )
            setResponse(methods)
        }
    }, [data])

    return {
        methods: response,
        loading,
        error,
        refetch,
    }
}

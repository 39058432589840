import React, { JSX } from 'react'

import { useFormik } from 'formik'
import {
    Stack,
    Box,
    Typography,
    TextField,
    Button,
    InputLabel,
    InputAdornment,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { AlternateEmail, NavigateBeforeOutlined } from '@mui/icons-material'
import { t } from 'i18next'
import { LoadingButton } from '@mui/lab'
import { useLocation, useNavigate } from 'react-router-dom'

import { paths } from '@auth/index.routes'
import ForgotPasswordFormScheme from './index.schema'
import { useAuth } from '@auth/hooks/auth'

interface ForgotPasswordFormProps {
    onSuccess: () => void
    redirectTo?: string | null
}

const ForgotPasswordForm = ({
    onSuccess,
    redirectTo,
}: ForgotPasswordFormProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar()
    const location = useLocation()
    const navigate = useNavigate()
    const { resetPassword, isLoading } = useAuth()

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordFormScheme,
        onSubmit: values => {
            resetPassword({ ...values, redirectTo }).then(response => {
                if (response?.success) {
                    onSuccess()
                } else {
                    enqueueSnackbar('Email not found', { variant: 'error' })
                }
            })
        },
    })

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="20px">
                    <Button
                        startIcon={<NavigateBeforeOutlined />}
                        onClick={() => navigate(paths.login)}
                        sx={{
                            marginBottom: '15px',
                            alignSelf: 'flex-start',
                            fontWeight: 500,
                            fontSize: '14px',
                            '& .MuiSvgIcon-root': {
                                fontSize: '24px !important',
                            },
                        }}
                    >
                        {t('auth:titles.clientAccountLoginTitle')}
                    </Button>
                    <Typography fontSize="24px" textAlign="center">
                        {t('auth:titles.forgotPassword')}
                    </Typography>
                    <Stack gap="8px">
                        <InputLabel htmlFor="email">
                            {t('auth:fields.email')}
                        </InputLabel>
                        <TextField
                            id="login-input"
                            fullWidth
                            autoComplete="off"
                            name="email"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            size="medium"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AlternateEmail />
                                    </InputAdornment>
                                ),
                            }}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                        />
                    </Stack>
                    <Stack gap="15px">
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            fullWidth
                            loading={isLoading}
                            sx={{ padding: '15px' }}
                        >
                            {t('auth:sendLetterToResetPassword')}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}

export default ForgotPasswordForm

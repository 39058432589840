import React, { JSX, useEffect, useState } from 'react'

import {
    Typography,
    Stack,
    Button,
    Box,
    useMediaQuery,
    IconButton,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import {
    AccountCircle,
    CheckCircleOutline,
    DeleteForever,
    DoneAll,
    DoNotDisturb,
} from '@mui/icons-material'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { useCourse } from 'hooks/courses'
import { LessonType, ModuleType } from 'api/root/generated'
import Module from './components/Module'
import { useAccessAPI, useAccess } from '@backoffice/hooks/accesses'
import { useDocumentTitle } from 'hooks/common'

const CourseClientAccessPage = (): JSX.Element => {
    const matches1200px = useMediaQuery('(max-width:1200px)')
    const matches800px = useMediaQuery('(max-width:800px)')
    const matches700px = useMediaQuery('(max-width:700px)')

    const { courseId, accessId } = useParams()
    const {
        access,
        loading: accessLoading,
        refetch,
    } = useAccess(accessId as string)
    const [selectedLessons, setSelectedLessons] = useState<string[]>([])

    useEffect(() => {
        if (access) {
            setSelectedLessons(
                access?.lessons.edges
                    .map(e => e?.node as LessonType)
                    .map(l => l.id as string) || []
            )
        }
    }, [access])

    const { enqueueSnackbar } = useSnackbar()

    const { course, error } = useCourse(courseId as string)

    useDocumentTitle(
        `${t('backoffice:pageTitles.clientAccessPage')} | ${course?.name}`
    )

    const { grantLessonAccess, loading } = useAccessAPI()

    const handleSelectAllLessons = () => {
        const allModules = course?.modules?.edges.map(
            e => e?.node
        ) as ModuleType[]

        const allLessons = allModules
            .map(m => m.lessons.edges.map(e => e?.node) as LessonType[])
            .flat()
            .map(l => l?.id as string)

        setSelectedLessons(allLessons)
    }

    const handleCancelSelection = () => {
        setSelectedLessons([])
    }

    const handleSelectLesson = (lesson: LessonType, selected: boolean) => {
        if (selected) {
            setSelectedLessons(prevState => [...prevState, lesson.id])
        } else {
            setSelectedLessons(prevState =>
                prevState.filter(id => id !== lesson.id)
            )
        }
    }

    const handleSelectModule = (module: ModuleType, selected: boolean) => {
        if (selected) {
            const lessonsToSelect = module.lessons.edges
                .map(e => e?.node)
                .map(l => l?.id as string)

            setSelectedLessons(prevState => {
                return [
                    ...prevState,
                    ...lessonsToSelect.filter(l => !prevState.includes(l)),
                ]
            })
        } else {
            const lessonsToDeselect = module.lessons.edges
                .map(e => e?.node)
                .map(l => l?.id as string)

            setSelectedLessons(prevState =>
                prevState.filter(id => !lessonsToDeselect.includes(id))
            )
        }
    }

    const allLessonsCount = (
        course?.modules?.edges.map(e => e?.node) as ModuleType[]
    )
        ?.map(m => m.lessons.edges.map(e => e?.node) as LessonType[])
        .flat().length

    const isAllLessonsSelected = allLessonsCount === selectedLessons.length

    const handleGrantAccess = (lessons: string[]) => {
        grantLessonAccess({
            input: {
                courseId: courseId as string,
                lessonIds: lessons,
                clientEmails: [access?.client?.email as string],
                redirectTo: `${window.location.origin}/${course?.id}`,
            },
        }).then(response => {
            if (response?.success) {
                if (lessons.length !== 0) {
                    enqueueSnackbar(
                        t(
                            'backoffice:access.grant.notifications.accessUpdateSuccess'
                        )
                    )
                } else {
                    enqueueSnackbar(
                        t(
                            'backoffice:access.grant.notifications.accessDeleteSuccess'
                        )
                    )
                }
                refetch()
            }
        })
    }

    const preselectedLessons =
        access?.lessons.edges
            .map(e => e?.node as LessonType)
            .map(l => l.id as string) || []
    const accessWasChanged =
        JSON.stringify(selectedLessons.sort()) !==
        JSON.stringify(preselectedLessons.sort())

    return (
        <Stack gap="25px" padding={matches1200px ? '10px' : '1% 2%'}>
            <Stack
                gap="15px"
                direction="column"
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <Stack direction="column" alignItems="flex-start" gap="10px">
                    <Stack direction="row" alignItems="center" gap="15px">
                        <AccountCircle
                            sx={{
                                fontSize: matches700px ? '55px' : '70px',
                                color: '#d6bf62',
                            }}
                        />
                        <Stack>
                            <Typography fontSize="max(min(2vw, 20px), 17px)">
                                {access?.client.email}
                            </Typography>
                            <Typography
                                fontSize="max(min(2vw, 18px), 14px)"
                                sx={theme => ({ color: '#949494' })}
                            >
                                {t(
                                    'backoffice:access.grant.accessesForCourse',
                                    {
                                        name: `"${course?.name}"`,
                                    }
                                )}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="15px"
                    sx={{
                        alignSelf: 'flex-end',
                        '& .MuiButton-root': {
                            fontSize: '16px',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: matches800px
                                ? '18px'
                                : matches1200px
                                ? '22px'
                                : '28px',
                        },
                    }}
                >
                    {selectedLessons.length > 0 && (
                        <Typography fontSize="18px" marginRight="20px">
                            {t('backoffice:access.grant.selectedCount', {
                                count: selectedLessons.length,
                            })}
                        </Typography>
                    )}

                    {matches700px ? (
                        <IconButton
                            color="secondary"
                            sx={theme => ({
                                border: `1px solid`,
                                borderColor: 'secondary',
                                borderRadius: '4px',
                            })}
                            onClick={
                                isAllLessonsSelected
                                    ? handleCancelSelection
                                    : handleSelectAllLessons
                            }
                        >
                            {isAllLessonsSelected ? (
                                <DoNotDisturb color="error" />
                            ) : (
                                <DoneAll />
                            )}
                        </IconButton>
                    ) : (
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={
                                isAllLessonsSelected ? (
                                    <DoNotDisturb color="error" />
                                ) : (
                                    <DoneAll />
                                )
                            }
                            onClick={
                                isAllLessonsSelected
                                    ? handleCancelSelection
                                    : handleSelectAllLessons
                            }
                        >
                            {isAllLessonsSelected
                                ? t('backoffice:access.grant.deselectAll')
                                : t('backoffice:access.grant.selectAll')}
                        </Button>
                    )}
                    {matches700px ? (
                        <IconButton
                            color="error"
                            sx={theme => ({
                                border: `1px solid`,
                                borderColor: isAllLessonsSelected
                                    ? 'error'
                                    : 'secondary',
                                borderRadius: '4px',
                            })}
                            onClick={() => handleGrantAccess([])}
                        >
                            <DeleteForever />
                        </IconButton>
                    ) : (
                        <Button
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteForever />}
                            onClick={() => handleGrantAccess([])}
                        >
                            {t('backoffice:access.grant.deleteAccess')}
                        </Button>
                    )}

                    {matches700px ? (
                        <IconButton
                            disabled={
                                selectedLessons.length === 0 ||
                                loading ||
                                !accessWasChanged
                            }
                            color="success"
                            sx={theme => ({
                                border: `1px solid`,
                                borderColor: 'secondary',
                                borderRadius: '4px',
                            })}
                            onClick={() => handleGrantAccess(selectedLessons)}
                        >
                            <CheckCircleOutline />
                        </IconButton>
                    ) : (
                        <Button
                            disabled={
                                selectedLessons.length === 0 ||
                                loading ||
                                !accessWasChanged
                            }
                            variant="outlined"
                            color="success"
                            startIcon={<CheckCircleOutline />}
                            onClick={() => handleGrantAccess(selectedLessons)}
                        >
                            {t('backoffice:access.grant.saveAccess')}
                        </Button>
                    )}
                </Stack>
            </Stack>
            <Box
                sx={{
                    height: '2px',
                    position: 'relative',
                    zIndex: 1,
                    background:
                        'linear-gradient(90deg, rgba(228,217,217,0) 10%, rgba(163,163,163,1) 100%)',
                }}
            />
            <Box>
                {course?.modules?.edges
                    .map(e => e?.node as ModuleType)
                    .map(m => (
                        <Module
                            selectedLessons={selectedLessons}
                            onSelectLesson={handleSelectLesson}
                            onSelectModule={handleSelectModule}
                            key={m?.id}
                            module={m}
                        />
                    ))}
            </Box>
        </Stack>
    )
}
export default CourseClientAccessPage

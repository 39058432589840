import React, { JSX } from 'react'

import {
    Typography,
    Stack,
    Divider,
    useMediaQuery,
    Zoom,
    Button,
} from '@mui/material'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { AddCircleOutline, QuestionMarkOutlined } from '@mui/icons-material'
import { Masonry } from '@mui/lab'

import { CourseForm } from '@backoffice/components/forms'
import { Image } from 'components'
import { useDialog, useDocumentTitle } from 'hooks/common'
import { useCourses } from 'hooks/courses'
import { paths } from '@backoffice/index.routes'
import General from './components/General'

const ContentPage = (): JSX.Element => {
    useDocumentTitle(t('backoffice:pageTitles.contentPage'))

    const lessThan1400px = useMediaQuery('(max-width:1400px)')
    const lessThan800px = useMediaQuery('(max-width:800px)')

    const navigate = useNavigate()
    const { open, close } = useDialog()

    const { courses, refetch: refetchCourses } = useCourses()

    const handleOpenCreateCourseModal = () => {
        open({
            component: CourseForm,
            props: {
                onSuccess: (action: 'add' | 'update', id: string) => {
                    close()
                    refetchCourses()
                    navigate(`../${paths.course(id)}`)
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const renderControlsPlate = (height: string) => {
        return (
            <Stack
                sx={theme => ({
                    boxSizing: 'border-box',
                    height,
                    padding: '0 25px 25px 0',
                    ...((courses.length === 0 || lessThan800px) && {
                        paddingRight: 0,
                    }),
                    overflow: 'hidden',
                    position: 'relative',
                    '& .MuiButton-root': {
                        justifyContent: 'flex-start',
                        width: '100%',
                        textAlign: 'left',
                        '& .MuiTypography-root': {
                            letterSpacing: '-1px',
                            transition: '.2s ease',
                            padding: '5px 10px',
                            fontSize: lessThan800px ? '18px' : '22px',
                            lineHeight: '23px',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'primary.main',
                            fontSize: lessThan800px ? '32px' : '42px',
                        },
                        '&.Mui-disabled': {
                            '& .MuiTypography-root': {
                                color: 'secondary.light',
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'secondary.light',
                            },
                        },
                    },
                })}
                gap="15px"
            >
                <Button
                    onClick={handleOpenCreateCourseModal}
                    variant="text"
                    startIcon={<AddCircleOutline />}
                >
                    <Typography>{t('backoffice:createCourse')}</Typography>
                </Button>
                <Divider flexItem />
                <Button
                    variant="text"
                    disabled
                    startIcon={<AddCircleOutline />}
                >
                    <Typography>
                        {t('backoffice:createSharedCourse')}
                    </Typography>
                </Button>
                <Divider flexItem />
                <Button
                    variant="text"
                    disabled
                    startIcon={<AddCircleOutline />}
                >
                    <Typography> {t('backoffice:createSingleton')}</Typography>
                </Button>
                {!lessThan1400px && <General courses={courses} />}
            </Stack>
        )
    }

    const renderPlate = (
        title: string,
        height: string,
        image: string,
        path: string
    ) => {
        return (
            <Zoom in key={path}>
                <Stack
                    onClick={() => navigate(path)}
                    sx={theme => ({
                        boxSizing: 'border-box',
                        height,
                        padding: '25px 0 0 0',
                        border: '2px solid #181818',
                        cursor: 'pointer',
                        overflow: 'hidden',
                        position: 'relative',
                        boxShadow: '0 0 19px -6px #131313',
                        '&:hover': {
                            transition: 'all .3s',
                            border: `2px solid ${theme.palette.primary.main}`,
                            '& img, & .MuiSvgIcon-root': {
                                transform: 'scale(1.1)',
                            },
                        },
                        '& img': {
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            position: 'absolute',
                            zIndex: 0,
                            transition: '.3s',
                        },
                    })}
                >
                    {image ? (
                        <Image src={image} alt="preview" />
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            height={height}
                            sx={{
                                background: '#3a3a3a',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                position: 'absolute',
                                zIndex: 0,
                            }}
                        >
                            <QuestionMarkOutlined
                                sx={{
                                    transition: '.3s',
                                    color: '#4e4e4e',
                                    fontSize: '200px',
                                }}
                            />
                        </Stack>
                    )}

                    <Typography
                        noWrap
                        variant="caption"
                        fontSize="max(min(2vw, 32px), 18px)"
                        lineHeight="max(min(2vw, 34px), 20px)"
                        sx={{
                            backgroundColor: 'rgba(47,47,47,1)',
                            letterSpacing: '-0.05em',
                            padding: '10px 20px',
                            zIndex: 1,
                            position: 'absolute',
                            // border: '1px solid #a4a4a4',
                            boxShadow: '0 4px 20px -4px rgba(0, 0, 0, 0.3)',
                            border: '1px solid #222',
                            borderWidth: '1px 1px 1px 0',
                            maxWidth: '75%',

                            // boxShadow: '0 0 4px -6px #000',
                        }}
                    >
                        {title}
                    </Typography>
                </Stack>
            </Zoom>
        )
    }

    const SCREEN_PADDING = lessThan1400px ? '5%' : '5% 15% 5%'
    const LARGE_PLATE_HEIGHT = lessThan800px
        ? '250px'
        : lessThan1400px
        ? '400px'
        : '500px'

    return (
        <Stack
            className="content"
            width="100%"
            alignItems="center"
            justifyContent="center"
            gap="25px"
            divider={<Divider flexItem />}
            padding={SCREEN_PADDING}
        >
            <Stack width="100%">
                <Stack width="100%" alignItems="center" justifyContent="center">
                    <Masonry
                        columns={
                            courses.length === 0 ? 1 : lessThan800px ? 1 : 2
                        }
                        spacing={0}
                    >
                        {renderControlsPlate(LARGE_PLATE_HEIGHT)}
                        {courses.map(c =>
                            renderPlate(
                                c.name,
                                LARGE_PLATE_HEIGHT,
                                c.appearance?.background?.img500x300 as string,
                                c.id
                            )
                        )}
                    </Masonry>
                </Stack>
            </Stack>
        </Stack>
    )
}
export default ContentPage

import { createTheme } from '@mui/material/styles'

const palette = {
    primary: {
        main: 'rgb(249 98 98)',
        light: '#e2d5f6',
        dark: '#6e5e86',
    },
    secondary: {
        main: '#f0f0f0',
        dark: '#3a3a3a',
        light: '#706f6f',
    },
    error: { main: '#ff7979' },
    success: { main: '#15cf74' },
    background: { default: '#181818', paper: 'rgba(122,122,122,0.77)' },
}

const theme = createTheme({
    palette: {
        ...palette,
        mode: 'dark',
    },
    breakpoints: {
        values: {
            xs: 500,
            sm: 768,
            md: 900,
            lg: 1200,
            xl: 1440,
        },
    },
    typography: {
        fontFamily: 'Roboto',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    textTransform: 'none',
                },
                textPrimary: {
                    textTransform: 'none',
                },
            },
        },
    },
})

export default theme

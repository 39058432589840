import React, { JSX } from 'react'

import { Typography, Stack, useMediaQuery } from '@mui/material'
import { t } from 'i18next'

import { useMe } from 'hooks/user'
import { UserType } from 'api/root/generated'
import Account from './components/Account'
import Socials from './components/Socials'
import Profile from './components/Profile'
import Payment from './components/Payment'
import { useDocumentTitle } from 'hooks/common'

const SettingsPage = () => {
    useDocumentTitle(
        `${t('backoffice:pageTitles.backoffice')} | ${t(
            'backoffice:pageTitles.settingsPage'
        )}`
    )

    const lessThan1400px = useMediaQuery('(max-width:1400px)')

    const { me } = useMe()
    const SCREEN_PADDING = lessThan1400px ? '5%' : '5% 15% 5%'

    console.log(me)

    return me ? (
        <Stack gap="35px" padding={SCREEN_PADDING}>
            <Typography fontSize="40px">
                {t('backoffice:settings.title')}
            </Typography>
            <Stack gap="35px">
                <Profile user={me as UserType} />
                <Socials user={me as UserType} />
                <Payment user={me as UserType} />
                <Account user={me as UserType} />
            </Stack>
        </Stack>
    ) : null
}
export default SettingsPage

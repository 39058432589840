import React from 'react'

import { Outlet } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Stack, useMediaQuery, Box } from '@mui/material'

import { Loader, ReactiveDialog, Logo, Image } from 'components'
import { useMe } from 'hooks/user'
import theme from './index.theme'
import { UserType } from 'api/auth/generated'

interface AuthLayoutProps {
    user: UserType
}

const AuthLayout = ({ user }: AuthLayoutProps) => {
    const { loading } = useMe()
    const tablet = useMediaQuery(
        theme.breakpoints.down('sm') && theme.breakpoints.up('xs')
    )
    const mobile = useMediaQuery(theme.breakpoints.down('xs'))
    if (loading) {
        return <Loader />
    }
    const HEADER_PADDING = mobile ? '15px' : tablet ? '20px 35px' : '25px 60px'

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ReactiveDialog />
            <Stack
                width="100%"
                sx={{
                    position: 'relative',
                    height: '100vh',
                    background: user?.appearance?.background?.original
                        ? 'rgba(0, 0, 0, .8)'
                        : 'radial-gradient(circle, rgba(47,47,47,1) 0%, rgba(24,24,24,1) 90%)',
                }}
            >
                {user?.appearance?.background?.original && (
                    <Image
                        src={user?.appearance?.background?.original}
                        sx={{
                            position: 'absolute',
                            zIndex: -2,
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            height: '100vh',
                            objectFit: 'cover',
                        }}
                    />
                )}
                <Stack padding={HEADER_PADDING}>
                    <Logo height={mobile ? '40px' : '65px'} />
                </Stack>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    flexGrow={1}
                >
                    <Stack
                        sx={{
                            borderRadius: '16px',
                            position: 'relative',
                            background: theme.palette.background.paper,
                            width: mobile ? '350px' : '475px',
                        }}
                    >
                        <Outlet />
                    </Stack>
                </Stack>
            </Stack>
        </ThemeProvider>
    )
}

export default AuthLayout

import React, { useCallback, useEffect, useState } from 'react'

import {
    Box,
    Checkbox,
    Divider,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material'

import { LessonType, ModuleType } from 'api/root/generated'
import Lesson from './Lesson'
import { Image } from 'components'
import checkbox from 'assets/images/checkbox.svg'
import checkboxChecked from 'assets/images/checkbox-checked.svg'

interface ModuleProps {
    module: ModuleType
    onSelectLesson: (lesson: LessonType, selected: boolean) => void
    onSelectModule: (module: ModuleType, selected: boolean) => void
    selectedLessons: string[]
}

interface LessonItem {
    id: string
    name: string
    videoPreviewUrl: string
    selected: boolean
}

const Module = ({
    module,
    onSelectLesson,
    onSelectModule,
    selectedLessons,
}: ModuleProps) => {
    const lessThan1000px = useMediaQuery('(max-width:1000px)')

    const [lessons, setLessons] = useState<LessonItem[]>([])

    useEffect(() => {
        setLessons(
            module.lessons.edges
                .map(e => e?.node)
                .map(l => ({
                    id: l?.id as string,
                    name: l?.name as string,
                    videoPreviewUrl: l?.videoPreview?.img520x200 as string,
                    selected: false,
                }))
        )
    }, [module])

    const renderLesson = useCallback(
        (card: LessonItem) => {
            const lesson = module.lessons.edges
                .map(n => n?.node)
                .find(l => l?.id === card.id)
            return (
                <Lesson
                    key={lesson?.id}
                    data={lesson as LessonType}
                    onSelect={onSelectLesson}
                    selected={selectedLessons.includes(lesson?.id as string)}
                />
            )
        },
        [module, lessons, selectedLessons]
    )

    return (
        <Stack key={module.id}>
            <Stack
                sx={{
                    padding: '15px 25px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
                    border: '1px solid #222',
                    background: 'rgba(0, 0, 0, 0.48)',
                }}
                direction="row"
                alignItems="center"
                divider={<Divider flexItem orientation="vertical" />}
                gap={lessThan1000px ? '15px' : '25px'}
                width="100%"
            >
                <Checkbox
                    disabled={lessons.length === 0}
                    icon={<Image src={checkbox} />}
                    checkedIcon={<Image src={checkboxChecked} />}
                    checked={
                        lessons.length > 0
                            ? lessons.every(l =>
                                  selectedLessons.includes(l?.id as string)
                              )
                            : false
                    }
                    onChange={(_, checked) => {
                        onSelectModule(module, checked)
                    }}
                    onClick={e => e.stopPropagation()}
                    sx={{
                        '&.Mui-disabled': {
                            opacity: '0.3',
                        },
                        padding: '5px',
                        '& > img': {
                            width: '28px',
                            height: '28px',
                        },
                    }}
                />
                <Typography
                    noWrap
                    sx={{
                        fontSize: 'max(min(1vw, 32px), 18px)',
                        maxWidth: '30%',
                        width: 'fit-content',
                        minWidth: '200px',
                    }}
                >
                    {module.name}
                </Typography>
            </Stack>
            <Box
                sx={{
                    padding: '25px',
                    width: '100%',
                }}
            >
                <Stack
                    sx={{
                        flexWrap: 'wrap',
                        '@media (max-width:480px)': {
                            flexWrap: 'nowrap',
                            flexDirection: 'column',
                        },
                    }}
                    direction="row"
                    gap="35px"
                >
                    {lessons.map(renderLesson)}
                </Stack>
            </Box>
        </Stack>
    )
}
export default Module

import React, { PropsWithChildren } from 'react'

import { IconButton, Stack, SxProps } from '@mui/material'
import { Instagram, Facebook } from '@mui/icons-material'

import TikTokIcon from '../../assets/images/TikTok'

export enum Socials {
    INSTAGRAM = 'instagram',
    TIKTOK = 'tiktok',
    FACEBOOK = 'facebook',
}

export interface SocialItem {
    value: Socials
    url: string
}

export interface SocialsListProps {
    items: SocialItem[]
    color?: string
    sx?: SxProps
}

const SocialsList = ({
    items,
    color = '#fff',
    sx = {},
}: PropsWithChildren<SocialsListProps>) => {
    const resolveIcon = (value: Socials) => {
        switch (value) {
            case Socials.INSTAGRAM:
                return <Instagram />
            case Socials.TIKTOK:
                return <TikTokIcon color={color} />
            case Socials.FACEBOOK:
                return <Facebook />
            default:
                return <Instagram />
        }
    }

    // TODO: Links should be opened on a new tab (_blank)
    return (
        <Stack
            direction="row"
            sx={{
                ...sx,
                '& .MuiButtonBase-root': {
                    padding: '5px',
                    '@media (max-width:700px)': {
                        padding: '5px',
                    },
                },
            }}
        >
            {items.map(i => (
                <IconButton
                    key={i.value}
                    sx={{
                        color,
                    }}
                    size="large"
                    onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        window.location = i.url
                    }}
                >
                    {resolveIcon(i.value)}
                </IconButton>
            ))}
        </Stack>
    )
}

export default SocialsList

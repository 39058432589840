import React, { JSX } from 'react'

import { useFormik } from 'formik'
import { Box, Stack, Typography, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import { t } from 'i18next'
import { LoadingButton } from '@mui/lab'

import { ResetPasswordFormScheme } from './index.schema'
import { useAuth } from '@auth/hooks/auth'

export const ResetPasswordInitialValues = {
    newPassword1: '',
    newPassword2: '',
}

interface ResetPasswordFormProps {
    uid: string
    token: string
    onSuccess: () => void
}

const ResetPasswordForm = ({
    uid,
    token,
    onSuccess,
}: ResetPasswordFormProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar()
    const { resetPasswordConfirm, isLoading } = useAuth()

    const formik = useFormik({
        initialValues: ResetPasswordInitialValues,
        validationSchema: ResetPasswordFormScheme,
        onSubmit: values => {
            resetPasswordConfirm({ ...values, uid, token })
                .then(response => {
                    if (response?.success) {
                        onSuccess()
                    }
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' })
                })
        },
    })

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="20px">
                    <Typography fontSize="24px" textAlign="center">
                        {t('auth:titles.resetPassword')}
                    </Typography>
                    <Stack gap="30px" width="100%" justifyContent="center">
                        <TextField
                            id="login-input"
                            fullWidth
                            autoComplete="off"
                            name="newPassword1"
                            variant="outlined"
                            label={t('auth:fields.newPassword1')}
                            value={formik.values.newPassword1}
                            onChange={formik.handleChange}
                            size="medium"
                            error={
                                formik.touched.newPassword1 &&
                                Boolean(formik.errors.newPassword1)
                            }
                            helperText={
                                formik.touched.newPassword1 &&
                                formik.errors.newPassword1
                            }
                        />
                        <TextField
                            id="login-input"
                            fullWidth
                            autoComplete="off"
                            name="newPassword2"
                            variant="outlined"
                            label={t('auth:fields.newPassword2')}
                            value={formik.values.newPassword2}
                            onChange={formik.handleChange}
                            size="medium"
                            error={
                                formik.touched.newPassword2 &&
                                Boolean(formik.errors.newPassword2)
                            }
                            helperText={
                                formik.touched.newPassword2 &&
                                formik.errors.newPassword2
                            }
                        />
                    </Stack>
                    <Stack gap="15px">
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            fullWidth
                            loading={isLoading}
                            sx={{ padding: '15px' }}
                        >
                            {t('auth:setNewPassword')}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}
export default ResetPasswordForm

import React, { useRef, useState } from 'react'

import {
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { t } from 'i18next'
import {
    DeleteOutline,
    EditOutlined,
    MoreVert,
    PlayCircle,
    QuestionMarkOutlined,
} from '@mui/icons-material'

import { Image } from 'components'
import { LessonType, ModuleType } from 'api/root/generated'

interface LessonProps {
    id: string
    name: string
    videoPreviewUrl: string
    data: LessonType
    onDelete: (lesson: LessonType) => void
    onOpen: (lesson: LessonType) => void
    onEdit: (module: ModuleType, lesson: LessonType) => void
}

const Lesson = ({
    id,
    name,
    videoPreviewUrl,
    onEdit,
    onDelete,
    onOpen,
    data,
}: LessonProps) => {
    const lessThan610px = useMediaQuery('(max-width:610px)')

    const [isActionsVisible, setActionsVisible] = useState(false)
    const [anchor, setAnchor] = useState<HTMLElement | null>(null)

    const ref = useRef<HTMLDivElement | null>(null)

    return (
        <Stack
            ref={ref}
            key={id}
            onClick={() => {}}
            gap={0}
            sx={theme => ({
                boxShadow: '0 0 7px #000',
                border: `2px solid transparent`,
                cursor: 'pointer',
                position: 'relative',
                width: lessThan610px ? '100%' : '240px',
                '&:hover': {
                    border: `2px solid ${theme.palette.primary.main}`,
                },
            })}
            onMouseEnter={() => {
                setActionsVisible(true)
            }}
            onMouseLeave={() => {
                setActionsVisible(false)
            }}
        >
            {isActionsVisible && (
                <Stack
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                    }}
                >
                    <IconButton
                        onClick={e => setAnchor(e.currentTarget)}
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, .5)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, .8)',
                            },
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        open={!!anchor}
                        anchorEl={anchor}
                        onClose={() => setAnchor(null)}
                    >
                        <MenuItem
                            sx={{
                                gap: '10px',
                            }}
                            onClick={() => {
                                setAnchor(null)
                                setActionsVisible(false)
                                onOpen(data)
                            }}
                        >
                            <PlayCircle />
                            <Typography>
                                {t(
                                    'backoffice:content.course.lesson.actions.open'
                                )}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            sx={{
                                gap: '10px',
                            }}
                            onClick={() => {
                                setAnchor(null)
                                setActionsVisible(false)
                                onEdit(data?.module as ModuleType, data)
                            }}
                        >
                            <EditOutlined />
                            <Typography>
                                {t(
                                    'backoffice:content.course.lesson.actions.edit'
                                )}
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            sx={{
                                gap: '10px',
                            }}
                            onClick={() => {
                                setAnchor(null)
                                setActionsVisible(false)
                                onDelete(data)
                            }}
                        >
                            <DeleteOutline />
                            <Typography>
                                {t(
                                    'backoffice:content.course.lesson.actions.delete'
                                )}
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Stack>
            )}
            {videoPreviewUrl ? (
                <Image
                    src={videoPreviewUrl as string}
                    sx={{
                        width: '100%',
                        height: '160px',
                        objectFit: 'cover',
                    }}
                    alt=""
                />
            ) : (
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    height="160px"
                >
                    <QuestionMarkOutlined
                        sx={{
                            color: '#4e4e4e',
                            fontSize: '105px',
                        }}
                    />
                </Stack>
            )}
            <Typography
                noWrap
                textAlign="right"
                sx={{
                    borderTop: '1px solid #2e2e2e',
                    padding: '10px',
                    maxWidth: '100%',
                }}
            >
                {name}
            </Typography>
        </Stack>
    )
}
export default Lesson

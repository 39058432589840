import React, { JSX, useState } from 'react'

import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Divider,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
    AccountCircle,
    ForwardToInbox,
    PublicRounded,
} from '@mui/icons-material'
import { t } from 'i18next'
import isEmail from 'validator/lib/isEmail'
import { useSnackbar } from 'notistack'

import { useDialog, useDocumentTitle } from 'hooks/common'
import { paths } from '@backoffice/index.routes'
import { useCourse } from 'hooks/courses'
import { SearchField } from 'components'
import { useAccessAPI, useAccesses } from '@backoffice/hooks/accesses'
import AccessCard from './components/AccessCard'
import { useMe } from 'hooks/user'
import { CourseAccessType, CourseStreamType } from 'api/root/generated'
import { useStreams } from '@backoffice/hooks/streams'

const CourseAccessPage = (): JSX.Element => {
    const matches1200px = useMediaQuery('(max-width:1200px)')
    const matches800px = useMediaQuery('(max-width:800px)')
    const matches700px = useMediaQuery('(max-width:700px)')

    const navigate = useNavigate()
    const { me } = useMe()
    const { courseId } = useParams()
    const { open, close } = useDialog()
    const { grantLessonAccess } = useAccessAPI()

    const { course } = useCourse(courseId as string)

    useDocumentTitle(
        `${t('backoffice:pageTitles.courseAccessPage')} | ${course?.name}`
    )

    const [search, setSearch] = useState('')
    const [selectedStream, setSelectedStream] =
        useState<CourseStreamType | null>(null)
    const [newClientValue, setNewClientValue] = useState('')
    const { enqueueSnackbar } = useSnackbar()

    const { streams, refetch: refetchStreams } = useStreams({
        courseId: courseId as string,
    })

    const { accesses, loading, error, refetch, networkStatus } = useAccesses(
        {
            courseId: courseId as string,
            ...(search && {
                search,
            }),
            streamId: selectedStream?.id,
            first: 20,
        },
        {
            fetchPolicy: 'cache-and-network',
        }
    )

    const [selectedClients, setSelectedClients] = useState<string[]>([])

    const allClientsEmails = accesses.map(a => a.client.email)

    const selectedClientsWithValidEmails = selectedClients.filter(c =>
        isEmail(c)
    )

    const handleSelectClient = (email: string, selected: boolean) => {
        if (selected) {
            setSelectedClients(prevState => [...prevState, email])
        } else {
            setSelectedClients(prevState =>
                prevState.filter(clientEmail => clientEmail !== email)
            )
        }
    }

    const handleGoToSelectContent = () => {
        const emails = selectedClients.filter(c => isEmail(c))
        navigate(
            `../${paths.courseGrantAccess(
                courseId as string
            )}?emails=${JSON.stringify(emails)}`
        )
    }

    const renderEmptyMessage = () => {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                padding="10% 0 0"
            >
                <Typography
                    fontSize="max(min(1vw, 20px), 14px)"
                    sx={theme => ({ color: '#949494' })}
                >
                    {t('backoffice:access.noAccessesGranted')}
                </Typography>
            </Stack>
        )
    }

    const handleChangeAccessStream = (
        access: Partial<CourseAccessType>,
        streamId?: string | null
    ) => {
        grantLessonAccess({
            input: {
                streamId,
                courseId: courseId as string,
                lessonIds:
                    access.lessons?.edges
                        .map(e => e?.node)
                        .map(l => l?.id as string) || [],
                clientEmails: [access.client?.email as string],
                redirectTo: `${window.location.origin}/${course?.id}`,
            },
        }).then(response => {
            if (response?.success) {
                refetch()
                enqueueSnackbar(
                    streamId
                        ? t('backoffice:access.notifications.userInScope')
                        : t('backoffice:access.notifications.userOutOfScope'),
                    { variant: 'success' }
                )
            }
        })
    }

    return (
        <Stack gap="25px" padding={matches1200px ? '10px' : '1% 2%'}>
            <Stack
                gap="15px"
                direction="column"
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <Stack direction="column" alignItems="flex-start" gap="10px">
                    <Stack direction="row" alignItems="center" gap="15px">
                        <PublicRounded
                            sx={{
                                fontSize: matches700px ? '55px' : '70px',
                                color: '#d6bf62',
                            }}
                        />
                        <Stack>
                            <Typography fontSize="max(min(2vw, 20px), 17px)">
                                {course?.name}
                            </Typography>
                            <Typography
                                fontSize="max(min(2vw, 18px), 14px)"
                                sx={theme => ({ color: '#949494' })}
                            >
                                {t('backoffice:access.grant.title')}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="15px"
                    sx={{
                        alignSelf: 'flex-end',
                        '& .MuiButton-root': {
                            fontSize: '16px',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: matches800px
                                ? '18px'
                                : matches1200px
                                ? '22px'
                                : '28px',
                        },
                    }}
                >
                    {selectedClientsWithValidEmails.length > 0 && (
                        <Typography fontSize="18px" marginRight="20px">
                            {t('backoffice:access.selectedCount', {
                                count: selectedClientsWithValidEmails.length,
                            })}
                        </Typography>
                    )}
                    {matches700px ? (
                        <IconButton
                            disabled={
                                selectedClientsWithValidEmails.length === 0
                            }
                            color="success"
                            sx={theme => ({
                                border: `1px solid`,
                                borderColor: 'secondary',
                                borderRadius: '4px',
                            })}
                            onClick={handleGoToSelectContent}
                        >
                            <ForwardToInbox />
                        </IconButton>
                    ) : (
                        <Button
                            disabled={
                                selectedClientsWithValidEmails.length === 0
                            }
                            variant="outlined"
                            color="success"
                            startIcon={<ForwardToInbox />}
                            onClick={handleGoToSelectContent}
                        >
                            {selectedClientsWithValidEmails.length > 0
                                ? t('backoffice:access.confirmShareAccess', {
                                      count: selectedClientsWithValidEmails.length,
                                  })
                                : t(
                                      'backoffice:access.confirmShareAccessDisabled'
                                  )}
                        </Button>
                    )}
                </Stack>
            </Stack>
            <Box
                sx={{
                    height: '2px',
                    position: 'relative',
                    zIndex: 1,
                    background:
                        'linear-gradient(90deg, rgba(228,217,217,0) 10%, rgba(163,163,163,1) 100%)',
                }}
            />
            <Stack gap="25px" divider={<Divider />}>
                <Stack gap="20px">
                    <Stack gap="5px">
                        <Typography fontSize="max(min(2vw, 24px), 15px)">
                            {t('backoffice:access.grantAccessToNewUserTitle')}
                        </Typography>
                        <Typography
                            fontSize="max(min(1vw, 16px), 12px)"
                            sx={theme => ({ color: '#949494' })}
                        >
                            {t('backoffice:access.grantAccessToNewUserCaption')}
                        </Typography>
                    </Stack>
                    <Stack direction="row" gap="15px" alignItems="center">
                        <Autocomplete
                            fullWidth
                            multiple
                            id="tags-filled"
                            options={[]}
                            freeSolo
                            value={selectedClients.filter(
                                c => !allClientsEmails.includes(c)
                            )}
                            renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip
                                        icon={<AccountCircle />}
                                        variant="outlined"
                                        label={option}
                                        color={
                                            isEmail(option)
                                                ? 'default'
                                                : 'error'
                                        }
                                        {...getTagProps({ index })}
                                        sx={{
                                            '.MuiChip-label': {
                                                fontSize: '16px',
                                            },
                                        }}
                                    />
                                ))
                            }
                            onChange={(e, values) => {
                                // TODO: REMOVING THE TAG DOESN"T WORK
                                setSelectedClients(prevState => [
                                    ...prevState,
                                    ...values.filter(
                                        v => !prevState.includes(v)
                                    ),
                                ])
                            }}
                            renderInput={params => {
                                return (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        name="email"
                                        onChange={e =>
                                            setNewClientValue(e.target.value)
                                        }
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                paddingLeft: '15px',
                                            },
                                        }}
                                        value={newClientValue}
                                        placeholder={
                                            t(
                                                'backoffice:access.grantAccessToNewUserPlaceholder'
                                            ) as string
                                        }
                                    />
                                )
                            }}
                        />
                    </Stack>
                </Stack>
                <Stack gap="20px">
                    <Stack direction="row" gap="15px" alignItems="center">
                        <Typography fontSize="max(min(2vw, 24px), 15px)">
                            {t('backoffice:access.currentClients')}
                        </Typography>
                        <Typography
                            sx={{
                                color: 'secondary.light',
                            }}
                            fontSize="max(min(2vw, 24px), 15px)"
                        >
                            ({course?.accessCount || 0})
                        </Typography>
                    </Stack>
                    {course?.accessCount === 0 ? (
                        renderEmptyMessage()
                    ) : (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                            >
                                <Box
                                    sx={{
                                        ...(!matches700px && {
                                            maxWidth: '25%',
                                        }),
                                    }}
                                >
                                    <SearchField
                                        sx={{
                                            minWidth: '300px',
                                        }}
                                        onChange={text => setSearch(text)}
                                        placeholder={
                                            t(
                                                'backoffice:access.search'
                                            ) as string
                                        }
                                    />
                                </Box>
                                {streams.length > 0 && (
                                    <TextField
                                        label={t(
                                            'backoffice:access.selectStreamLabel'
                                        )}
                                        variant="outlined"
                                        select
                                        value={selectedStream?.id || ''}
                                        onChange={e =>
                                            setSelectedStream(
                                                streams.find(
                                                    s => s.id === e.target.value
                                                ) as CourseStreamType
                                            )
                                        }
                                        sx={{
                                            width: '300px',
                                            '& .MuiFormLabel-root': {
                                                color: '#6e6e6e',
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            {t(
                                                'backoffice:access.withoutStream'
                                            )}
                                        </MenuItem>
                                        <Divider />
                                        {streams.map(option => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            </Stack>

                            <Stack direction="row" flexWrap="wrap" gap="15px">
                                {accesses.map(a => (
                                    <AccessCard
                                        streams={streams}
                                        onStreamChange={
                                            handleChangeAccessStream
                                        }
                                        key={a.id}
                                        access={a}
                                        onSelect={handleSelectClient}
                                        selected={selectedClients.includes(
                                            a.client.email as string
                                        )}
                                        onOpen={access => {
                                            navigate(
                                                `../${paths.courseClientAccess(
                                                    courseId as string,
                                                    access.id as string
                                                )}`
                                            )
                                        }}
                                        onCopyAccessLink={email => {
                                            const url = `${window.location.origin}/authorization/activate-account`
                                            const getParams = `?redirectTo=${window.location.origin}/${courseId}&email=${email}`

                                            navigator.clipboard.writeText(
                                                `${url}${getParams}`
                                            )
                                            enqueueSnackbar(
                                                t(
                                                    'backoffice:access.accessLinkCopied'
                                                ),
                                                { variant: 'success' }
                                            )
                                        }}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}
export default CourseAccessPage

import React, { JSX } from 'react'

import { Typography, Stack, Divider, Button } from '@mui/material'
import { t } from 'i18next'
import { EditOutlined } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import Layout from './Layout'
import {
    WayForPayForm,
    MonobankForm,
    LiqPayForm,
    StripeForm,
    LiqPayFormValues,
    StripeFormValues,
    WayForPayFormValues,
    MonobankFormValues,
} from './modals'
import { UserType } from 'api/root/generated'
import { useDialog } from 'hooks/common'
import monoIcon from 'assets/images/monobank_icon.png'
import wayForPayIcon from 'assets/images/wayforpay_icon.png'
import stripeIcon from 'assets/images/stripe_icon.png'
import liqPayIcon from 'assets/images/liqpay_icon.png'
import { Image } from 'components'
import { usePaymentAPI } from 'hooks/payment'
import { PAYMENT_SERVICE } from 'utils/payment'

interface AccountProps {
    user: UserType
}

enum PaymentServices {
    WAY_FOR_PAY = 'WAY_FOR_PAY',
    MONOBANK = 'MONOBANK',
    STRIPE = 'STRIPE',
    LIQ_PAY = 'LIQ_PAY',
}

const components = {
    [PaymentServices.WAY_FOR_PAY]: WayForPayForm,
    [PaymentServices.MONOBANK]: MonobankForm,
    [PaymentServices.STRIPE]: StripeForm,
    [PaymentServices.LIQ_PAY]: LiqPayForm,
}

const Payment = ({ user }: AccountProps): JSX.Element => {
    const { open, close } = useDialog()
    const { setPaymentMethod } = usePaymentAPI()
    const { enqueueSnackbar } = useSnackbar()

    const handleSetUpPaymentMethod = ({
        payload,
        paymentService,
    }:
        | WayForPayFormValues
        | MonobankFormValues
        | StripeFormValues
        | LiqPayFormValues) => {
        setPaymentMethod({
            input: {
                paymentService,
                payload: JSON.stringify(payload),
            },
        }).then(response => {
            if (response?.success) {
                enqueueSnackbar('dsqddq', { variant: 'success' })
                close()
            }
        })
    }

    const handleOpenModal = (type: PaymentServices) => {
        const component = components[type]
        open({
            component,
            props: {
                paymentService: PAYMENT_SERVICE[type],
                onSubmit: handleSetUpPaymentMethod,
                onClose: close,
            },
            options: {
                onClose: close,
            },
        })
    }

    const userPaymentDetails = {
        monobank: {
            token: '1234213321dsqdqdqdqdqd',
        },
        wayForPay: {
            merchant_login: 'sippincup',
            merchant_secret_key: '12351ds1dsd1d1233',
        },
        liqpay: {
            private_key: '1231dw1d1d1321321',
            public_key: 'dsqsd1_11',
        },
        stripe: {
            secret_key: null,
        },
    }

    const renderConfiguredPaymentSystemButton = (
        type: PaymentServices,
        title: string,
        value: string
    ) => {
        return (
            <Button
                onClick={() => handleOpenModal(type)}
                endIcon={<EditOutlined />}
                color="secondary"
                variant="outlined"
            >
                <Typography
                    fontSize="15px"
                    color="secondary.light"
                    sx={{
                        marginRight: '10px',
                    }}
                >
                    {title}
                </Typography>
                <Typography fontSize="17px">{value}</Typography>
            </Button>
        )
    }

    const renderConfigureButton = (type: PaymentServices) => {
        return (
            <Button onClick={() => handleOpenModal(type)} variant="outlined">
                {t('backoffice:settings.payment.setUpButton')}
            </Button>
        )
    }

    return (
        <Layout title={t('backoffice:settings.payment.title')}>
            <Stack gap="15px" divider={<Divider />}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack direction="row" alignItems="center" gap="15px">
                        <a
                            style={{ fontSize: 0 }}
                            href="https://wayforpay.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image
                                src={wayForPayIcon}
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                }}
                            />
                        </a>

                        <Stack>
                            <Typography fontSize="22px">
                                {t(
                                    'backoffice:settings.payment.wayForPayLabel'
                                )}
                            </Typography>
                            <Typography
                                fontSize="17px"
                                sx={{ color: 'secondary.light' }}
                                variant="caption"
                            >
                                {t(
                                    'backoffice:settings.payment.wayForPayCaption'
                                )}
                            </Typography>
                        </Stack>
                    </Stack>

                    {userPaymentDetails.wayForPay.merchant_login
                        ? renderConfiguredPaymentSystemButton(
                              PaymentServices.WAY_FOR_PAY,
                              'Merchant login:',
                              userPaymentDetails.wayForPay.merchant_login
                          )
                        : renderConfigureButton(PaymentServices.WAY_FOR_PAY)}
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack direction="row" alignItems="center" gap="15px">
                        <a
                            style={{ fontSize: 0 }}
                            href="https://www.stripe.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image
                                src={stripeIcon}
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                }}
                            />
                        </a>

                        <Stack>
                            <Typography fontSize="22px">
                                {t('backoffice:settings.payment.stripeLabel')}
                            </Typography>
                            <Typography
                                fontSize="17px"
                                sx={{ color: 'secondary.light' }}
                                variant="caption"
                            >
                                {t('backoffice:settings.payment.stripeCaption')}
                            </Typography>
                        </Stack>
                    </Stack>

                    {userPaymentDetails.stripe.secret_key
                        ? renderConfiguredPaymentSystemButton(
                              PaymentServices.STRIPE,

                              'Secret key:',
                              userPaymentDetails.stripe
                                  ?.secret_key as unknown as string
                          )
                        : renderConfigureButton(PaymentServices.STRIPE)}
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack direction="row" alignItems="center" gap="15px">
                        <a
                            style={{ fontSize: 0 }}
                            href="https://www.monobank.ua"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image
                                src={monoIcon}
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                }}
                            />
                        </a>

                        <Stack>
                            <Typography fontSize="22px">
                                {t('backoffice:settings.payment.monobankLabel')}
                            </Typography>
                            <Typography
                                fontSize="17px"
                                sx={{ color: 'secondary.light' }}
                                variant="caption"
                            >
                                {t(
                                    'backoffice:settings.payment.monobankCaption'
                                )}
                            </Typography>
                        </Stack>
                    </Stack>

                    {userPaymentDetails.monobank.token
                        ? renderConfiguredPaymentSystemButton(
                              PaymentServices.MONOBANK,

                              'Token:',
                              userPaymentDetails.monobank
                                  ?.token as unknown as string
                          )
                        : renderConfigureButton(PaymentServices.MONOBANK)}
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack direction="row" alignItems="center" gap="15px">
                        <a
                            style={{ fontSize: 0 }}
                            href="https://www.liqpay.ua"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image
                                src={liqPayIcon}
                                sx={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                }}
                            />
                        </a>

                        <Stack>
                            <Typography fontSize="22px">
                                {t('backoffice:settings.payment.liqPayLabel')}
                            </Typography>

                            <Typography
                                fontSize="17px"
                                sx={{ color: 'secondary.light' }}
                                variant="caption"
                            >
                                {t('backoffice:settings.payment.liqPayCaption')}
                            </Typography>
                        </Stack>
                    </Stack>

                    {userPaymentDetails.liqpay.public_key
                        ? renderConfiguredPaymentSystemButton(
                              PaymentServices.LIQ_PAY,

                              'Public key:',
                              userPaymentDetails.liqpay
                                  ?.public_key as unknown as string
                          )
                        : renderConfigureButton(PaymentServices.LIQ_PAY)}
                </Stack>
            </Stack>
        </Layout>
    )
}
export default Payment

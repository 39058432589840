import React from 'react'

import {
    LoginPage,
    ForgotPasswordPage,
    AccountActivationPage,
    ResetPasswordPage,
    EmailVerificationPage,
} from '@auth/components/pages'

const paths = {
    login: '/authorization',
    signup: 'signup',
    resetPassword: 'reset-password',
    forgotPassword: 'forgot-password',
    emailVerification: 'email-verification',
    accountActivation: 'activate-account',
}

const routes = [
    {
        path: paths.login,
        element: <LoginPage />,
    },
    {
        path: paths.forgotPassword,
        element: <ForgotPasswordPage />,
    },
    {
        path: paths.resetPassword,
        element: <ResetPasswordPage />,
    },
    {
        path: paths.emailVerification,
        element: <EmailVerificationPage />,
    },
    {
        path: paths.accountActivation,
        element: <AccountActivationPage />,
    },
]

export { routes, paths }

import React, { useRef, useMemo, useCallback } from 'react'

import { debounce } from 'lodash'
import { Box, Stack } from '@mui/material'

import { Input } from 'components'

interface ColorPickerProps {
    value: string
    onChange: (value: string) => void
}

const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
    const ref = useRef<HTMLInputElement>(null)

    const handleClick = () => {
        if (ref.current) {
            ref.current?.click()
        }
    }

    const debouncedOnChange = useMemo(() => debounce(onChange, 300), [onChange])

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            debouncedOnChange(e.target.value)
        },
        [debouncedOnChange]
    )

    return (
        <Stack>
            <Box
                sx={{
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                    border: `1px solid white`,
                    backgroundColor: value,
                    cursor: 'pointer',
                }}
                onClick={handleClick}
            />
            <Input
                ref={ref}
                type="color"
                value={value}
                sx={{
                    height: 0,
                    width: 0,
                    visibility: 'hidden',
                }}
                onChange={handleChange}
            />
        </Stack>
    )
}

export default ColorPicker

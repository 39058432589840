import { useQuery } from '@apollo/client'

import { BackofficePreferencesDocument } from '../../../api/root/generated'

export const usePreferences = () => {
    const { data, loading, error, refetch } = useQuery(
        BackofficePreferencesDocument
    )

    let preferences

    if (data?.backofficePreferences) {
        preferences = data.backofficePreferences
    }

    return {
        preferences,
        loading: loading || typeof data === 'undefined',
        error,
        refetch,
    }
}

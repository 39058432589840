import React from 'react'

import {
    CourseAccessPage,
    FeedbackPage,
    CourseClientAccessPage,
    CourseStreamPage,
    CourseGrantAccessPage,
    DashboardPage,
    SettingsPage,
    NotFoundPage,
    ContentPage,
    CoursePage,
    NotificationsPage,
} from '@backoffice/components/pages'

const paths = {
    backoffice: `/backoffice`,
    feedback: `feedback`,
    access: `access`,
    promotion: `promotion`,
    content: `content`,
    settings: `settings`,
    notifications: `notifications`,
    statistics: `statistics`,
    course: (courseId: string) => `content/${courseId}`,
    courseAccess: (courseId: string) => `content/${courseId}/access`,
    courseStream: (courseId: string) => `content/${courseId}/stream`,
    courseGrantAccess: (courseId: string) => `content/${courseId}/access/grant`,
    courseClientAccess: (courseId: string, accessId: string) =>
        `content/${courseId}/access/${accessId}`,
}

const routes = [
    {
        path: paths.backoffice,
        element: <DashboardPage />,
    },
    {
        path: paths.feedback,
        element: <FeedbackPage />,
    },
    {
        path: paths.content,
        element: <ContentPage />,
    },
    {
        path: paths.settings,
        element: <SettingsPage />,
    },
    {
        path: paths.notifications,
        element: <NotificationsPage />,
    },
    {
        path: paths.course(':courseId'),
        element: <CoursePage />,
    },
    {
        path: paths.courseAccess(':courseId'),
        element: <CourseAccessPage />,
    },
    {
        path: paths.courseStream(':courseId'),
        element: <CourseStreamPage />,
    },
    {
        path: paths.courseGrantAccess(':courseId'),
        element: <CourseGrantAccessPage />,
    },
    {
        path: paths.courseClientAccess(':courseId', ':accessId'),
        element: <CourseClientAccessPage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
]

export { paths, routes }

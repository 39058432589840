import React from 'react'

import {
    Button,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    useMediaQuery,
} from '@mui/material'
import { AddOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material'
import { t } from 'i18next'

import { CourseStreamType } from 'api/root/generated'

interface ActionsProps {
    onCreate: () => void
    onEdit: (stream: CourseStreamType) => void
    onDelete: (stream: CourseStreamType) => void
    onStreamChange: (stream: CourseStreamType) => void
    streams: CourseStreamType[]
    selected: CourseStreamType | null
}

const Actions = ({
    selected,
    onCreate,
    streams,
    onStreamChange,
    onEdit,
    onDelete,
}: ActionsProps) => {
    const matches1200px = useMediaQuery('(max-width:1200px)')
    const matches800px = useMediaQuery('(max-width:800px)')
    const matches700px = useMediaQuery('(max-width:700px)')

    return (
        <Stack
            direction="row"
            alignItems="center"
            gap="15px"
            sx={{
                alignSelf: 'flex-end',
                '& .MuiButton-root': {
                    fontSize: '16px',
                },
                '& .MuiSvgIcon-root': {
                    fontSize: matches800px
                        ? '18px'
                        : matches1200px
                        ? '22px'
                        : '28px',
                },
            }}
        >
            <Stack direction="row" gap="20px">
                {matches700px ? (
                    <IconButton
                        color="success"
                        sx={theme => ({
                            border: `1px solid #66bb6a`,
                            borderRadius: '4px',
                        })}
                        onClick={() => onCreate()}
                    >
                        <AddOutlined />
                    </IconButton>
                ) : (
                    <Button
                        variant="outlined"
                        color="success"
                        startIcon={<AddOutlined />}
                        onClick={() => onCreate()}
                    >
                        {t('backoffice:stream.actions.create')}
                    </Button>
                )}
                {streams.length > 0 && (
                    <TextField
                        label={t('backoffice:stream.actions.selectLabel')}
                        variant="outlined"
                        select
                        size="small"
                        value={selected?.id || ''}
                        onChange={e =>
                            onStreamChange(
                                streams.find(
                                    s => s.id === e.target.value
                                ) as CourseStreamType
                            )
                        }
                        sx={{
                            flexGrow: '1',
                            width: '300px',
                            '& .MuiFormLabel-root': {
                                color: '#6e6e6e',
                            },
                        }}
                    >
                        {streams.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                )}

                {selected && (
                    <>
                        {matches700px ? (
                            <IconButton
                                color="error"
                                sx={theme => ({
                                    border: `1px solid #66bb6a`,
                                    borderRadius: '4px',
                                })}
                                onClick={() => onDelete(selected)}
                            >
                                <DeleteOutline />
                            </IconButton>
                        ) : (
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteOutline />}
                                onClick={() => onDelete(selected)}
                            >
                                {t('backoffice:stream.actions.delete')}
                            </Button>
                        )}
                        {matches700px ? (
                            <IconButton
                                color="warning"
                                sx={theme => ({
                                    border: `1px solid #66bb6a`,
                                    borderRadius: '4px',
                                })}
                                onClick={() => onEdit(selected)}
                            >
                                <EditOutlined />
                            </IconButton>
                        ) : (
                            <Button
                                variant="outlined"
                                color="warning"
                                startIcon={<EditOutlined />}
                                onClick={() => onEdit(selected)}
                            >
                                {t('backoffice:stream.actions.edit')}
                            </Button>
                        )}
                    </>
                )}
            </Stack>
        </Stack>
    )
}

export default Actions

import React from 'react'

import { Button, IconButton, Stack, useMediaQuery } from '@mui/material'
import {
    EditOutlined,
    PublicRounded,
    CallSplitRounded,
} from '@mui/icons-material'
import { t } from 'i18next'

interface ActionsProps {
    onAccess: () => void
    onEdit: () => void
    onStream?: () => void
}

const Actions = ({ onAccess, onEdit, onStream }: ActionsProps) => {
    const matches1200px = useMediaQuery('(max-width:1200px)')
    const matches800px = useMediaQuery('(max-width:800px)')
    const matches700px = useMediaQuery('(max-width:700px)')

    return (
        <Stack
            direction="row"
            alignItems="center"
            gap="15px"
            sx={{
                alignSelf: 'flex-end',
                '& .MuiButton-root': {
                    fontSize: '16px',
                },
                '& .MuiSvgIcon-root': {
                    fontSize: matches800px
                        ? '18px'
                        : matches1200px
                        ? '22px'
                        : '28px',
                },
            }}
        >
            {matches700px ? (
                <IconButton
                    color="secondary"
                    sx={theme => ({
                        border: `1px solid #f0f0f0`,
                        borderRadius: '4px',
                    })}
                    onClick={onStream}
                >
                    <CallSplitRounded />
                </IconButton>
            ) : (
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<CallSplitRounded />}
                    onClick={onStream}
                >
                    {t('backoffice:course.streams')}
                </Button>
            )}
            {matches700px ? (
                <IconButton
                    color="info"
                    sx={theme => ({
                        border: `1px solid #29b6f6`,
                        borderRadius: '4px',
                    })}
                    onClick={onAccess}
                >
                    <PublicRounded />
                </IconButton>
            ) : (
                <Button
                    variant="outlined"
                    color="info"
                    startIcon={<PublicRounded />}
                    onClick={onAccess}
                >
                    {t('backoffice:course.grantAccess')}
                </Button>
            )}
            {matches700px ? (
                <IconButton
                    color="warning"
                    sx={theme => ({
                        border: `1px solid #ffa726`,
                        borderRadius: '4px',
                    })}
                    onClick={onEdit}
                >
                    <EditOutlined />
                </IconButton>
            ) : (
                <Button
                    variant="outlined"
                    color="warning"
                    startIcon={<EditOutlined />}
                    onClick={onEdit}
                >
                    {t('backoffice:content.course.editTitle')}
                </Button>
            )}
        </Stack>
    )
}

export default Actions

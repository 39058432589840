import React, { JSX } from 'react'

import { useSearchParams } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'
import { t } from 'i18next'

import cup from 'assets/images/SP-CUP.png'
import { Image } from 'components'
import { useDocumentTitle } from 'hooks/common'

const EmailVerificationPage = (): JSX.Element => {
    useDocumentTitle(t('auth:pageTitles.emailVerification'))

    const [searchParams] = useSearchParams()

    const isConfirmation = searchParams.get('mode') === 'confirmation'

    return (
        <Stack alignItems="center" gap="50px">
            <Image sx={{ maxWidth: '130px' }} src={cup} />
            <Typography
                fontSize="26px"
                textAlign="center"
                lineHeight="27px"
                letterSpacing="-1px"
            >
                На вашу електронну адресу надiслано лист, перейдiть за
                посиланням у ньому, щоб{' '}
                <Typography
                    color="primary"
                    fontSize="26px"
                    lineHeight="27px"
                    letterSpacing="-1px"
                >
                    {isConfirmation
                        ? 'активувати ваш аккаунт'
                        : 'встановити новий пароль'}
                </Typography>
            </Typography>
        </Stack>
    )
}
export default EmailVerificationPage

import { useEffect } from 'react'

import { useSearchParams } from 'react-router-dom'

import { useAuth } from '@auth/hooks/auth'

export interface Credentials {
    uid: string
    token: string
}

interface useTokenVerificationProps {
    onSuccess: (params: Credentials) => void
    onFinish: () => void
    skip?: boolean
    token: string | null
    uid?: string | null
}

export const useTokenVerification = ({
    token,
    uid,
    onSuccess,
    onFinish,
    skip = false,
}: useTokenVerificationProps) => {
    const { verifyEmailToken } = useAuth()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        if (skip) {
            onSuccess({ uid: '', token: '' })
            onFinish()
        } else {
            const params = { uid, token }

            verifyEmailToken(params as Credentials)
                .then(response => {
                    if (response?.success) {
                        onSuccess(params as Credentials)
                    }
                })
                .finally(onFinish)
        }
    }, [searchParams, skip])
}

import { object, string } from 'yup'
import { t } from 'i18next'

export interface CourseStreamFormValues {
    name: string
}

export const CourseStreamFormScheme = object({
    name: string().required(t('auth:errors.required-field') || ''),
})

import React, { JSX } from 'react'

import { useFormik } from 'formik'
import { Box, Stack, Typography, TextField } from '@mui/material'
import { t } from 'i18next'
import { LoadingButton } from '@mui/lab'

import { CourseStreamFormScheme, CourseStreamFormValues } from './index.schema'
import { CourseStreamType } from 'api/root/generated'
import { useStreamsAPI } from '@backoffice/hooks/streams'

interface CourseStreamFormProps {
    stream?: CourseStreamType
    courseId: string
    onSuccess?: () => void
}

const CourseStreamForm = ({
    stream,
    courseId,
    onSuccess,
}: CourseStreamFormProps): JSX.Element => {
    const { create, update, loading } = useStreamsAPI()

    const formik = useFormik({
        initialValues: stream
            ? { name: stream.name }
            : {
                  name: '',
              },
        validationSchema: CourseStreamFormScheme,
        onSubmit: (values: CourseStreamFormValues) => {
            if (stream) {
                update({
                    input: {
                        streamId: stream.id,
                        schedule: stream.schedule,
                        ...values,
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.()
                    }
                })
            } else {
                create({
                    input: {
                        courseId,
                        ...values,
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.()
                    }
                })
            }
        },
    })

    return (
        <Box
            sx={{
                padding: '25px',
                width: '100%',
                maxWidth: '500px',
                minWidth: '500px',
                '@media (max-width:550px)': {
                    padding: '25px',
                },
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="25px">
                    <Stack>
                        <Typography
                            sx={{
                                fontSize: '20px',
                            }}
                        >
                            {stream
                                ? t('backoffice:stream.form.saveStream')
                                : t('backoffice:stream.form.createStream')}
                        </Typography>
                    </Stack>
                    <TextField
                        fullWidth
                        autoComplete="off"
                        name="name"
                        variant="outlined"
                        label={t('backoffice:stream.form.streamName')}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                    />
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        type="submit"
                        fullWidth
                        sx={{ padding: '15px' }}
                    >
                        {stream
                            ? t('backoffice:stream.form.saveStream')
                            : t('backoffice:stream.form.createStream')}
                    </LoadingButton>
                </Stack>
            </form>
        </Box>
    )
}

export default CourseStreamForm

import React, { JSX } from 'react'

import { t } from 'i18next'
import { Button, Stack, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

export interface TextConfirmationProps {
    onApprove: () => void
    onReject: () => void
    title: string
    message: string
}

const TextConfirmation = ({
    onApprove,
    onReject,
    title,
    message,
}: TextConfirmationProps): JSX.Element => {
    return (
        <Stack
            sx={{
                minWidth: '400px',
                padding: '40px',
            }}
            gap="25px"
        >
            <Typography fontSize="20px">{title}</Typography>
            <Stack direction="row" alignItems="center" gap="25px">
                <InfoOutlined
                    color="warning"
                    sx={{
                        fontSize: '55px',
                    }}
                />
                <Typography
                    sx={{
                        maxWidth: '300px',
                    }}
                    fontSize="15px"
                >
                    {message}
                </Typography>
            </Stack>
            <Stack justifyContent="flex-end" gap="15px" direction="row">
                <Button color="error" variant="outlined" onClick={onReject}>
                    {t('backoffice:confirmation.reject')}
                </Button>
                <Button onClick={onApprove}>
                    {t('backoffice:confirmation.approve')}
                </Button>
            </Stack>
        </Stack>
    )
}
export default TextConfirmation

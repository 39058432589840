import React from 'react'

import { Outlet } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { Loader, ReactiveDialog } from 'components'
import { useMe } from 'hooks/user'
import theme from '@storefront/index.theme'

const StorefrontLayout = () => {
    const { loading } = useMe()

    if (loading) {
        return <Loader />
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ReactiveDialog />
            <Outlet />
        </ThemeProvider>
    )
}

export default StorefrontLayout

import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    CourseStreamType,
    CreateCourseStreamDocument,
    MutationCreateCourseStreamArgs,
    CreateCourseStreamMutation,
    UpdateCourseStreamDocument,
    MutationUpdateCourseStreamArgs,
    UpdateCourseStreamMutation,
    CourseStreamListQueryVariables,
    CourseStreamListDocument,
    DeleteCourseStreamDocument,
    DeleteCourseStreamMutation,
    MutationDeleteCourseStreamArgs,
} from 'api/root/generated'

export const useStreams = (
    variables: CourseStreamListQueryVariables,
    options = {}
) => {
    const [response, setResponse] = useState<CourseStreamType[]>([])

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { data, loading, error, refetch, networkStatus } = useQuery(
        CourseStreamListDocument,
        {
            variables,
            ...options,
        }
    )

    useEffect(() => {
        if (data?.courseStreamList) {
            const streams: CourseStreamType[] =
                data?.courseStreamList.edges.map(
                    edge => edge?.node as CourseStreamType
                )
            setResponse(streams)
        }
    }, [data])

    return {
        streams: response,
        loading,
        error,
        refetch,
        networkStatus,
    }
}

export const useStreamsAPI = () => {
    const [createStream, { loading: creating }] = useMutation(
        CreateCourseStreamDocument
    )

    const [updateStream, { loading: updating }] = useMutation(
        UpdateCourseStreamDocument
    )

    const [deleteStream, { loading: deleting }] = useMutation(
        DeleteCourseStreamDocument
    )

    const create = (data: MutationCreateCourseStreamArgs) => {
        return createStream({ variables: data }).then(
            (response: FetchResult<CreateCourseStreamMutation>) => {
                return response?.data?.createCourseStream
            }
        )
    }
    const update = (data: MutationUpdateCourseStreamArgs) => {
        return updateStream({ variables: data }).then(
            (response: FetchResult<UpdateCourseStreamMutation>) => {
                return response?.data?.updateCourseStream
            }
        )
    }
    const remove = (data: MutationDeleteCourseStreamArgs) => {
        return deleteStream({ variables: data }).then(
            (response: FetchResult<DeleteCourseStreamMutation>) => {
                return response?.data?.deleteCourseStream
            }
        )
    }

    return {
        create,
        update,
        remove,
        loading: creating || updating || deleting,
    }
}

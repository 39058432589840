import React, { JSX } from 'react'

import { useNavigate } from 'react-router-dom'
import { Box, SxProps } from '@mui/material'

import logo from 'assets/images/logo.svg'
import { paths } from '@storefront/index.routes'
import { Image } from 'components'

export interface LogoProps {
    preventClick?: boolean
    onClick?: () => void
    height?: string
    sx?: SxProps
    noColor?: boolean
    src?: string
}

const Logo = ({
    preventClick = false,
    height = '65px',
    sx = {},
    noColor = false,
    onClick,
    src = logo,
}: LogoProps): JSX.Element => {
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                ...sx,
                height,
            }}
        >
            <Image
                onClick={() => {
                    if (onClick) {
                        onClick()
                    } else {
                        navigate(`../${paths.expert}`)
                    }
                }}
                src={src}
                alt="logo"
                sx={{
                    cursor: 'pointer',
                    height: '100%',
                    pointerEvents: preventClick ? 'none' : 'initial',
                    ...(noColor && {
                        filter: 'grayscale(1)',
                    }),
                }}
            />
        </Box>
    )
}

export default Logo

import React, { JSX } from 'react'

import { t } from 'i18next'
import { Button, Stack, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import { CourseType, ModuleType, LessonType } from 'api/root/generated'

export interface ResourceConfirmationProps<T> {
    onApprove: () => void
    onReject: () => void
    data: T
}

const ResourceConfirmation = <T extends ModuleType | CourseType | LessonType>({
    onApprove,
    onReject,
    data,
}: ResourceConfirmationProps<T>): JSX.Element => {
    return (
        <Stack
            sx={{
                minWidth: '400px',
                padding: '40px',
            }}
            gap="25px"
        >
            <Typography
                sx={{
                    fontSize: '20px',
                    maxWidth: '500px',
                }}
            >
                {t('backoffice:confirmation.title')}
            </Typography>
            <Typography
                sx={{
                    fontSize: '24px',
                    maxWidth: '400px',
                }}
            >
                {data.name}
            </Typography>
            <Stack direction="row" alignItems="center" gap="15px">
                <InfoOutlined
                    color="warning"
                    sx={{
                        fontSize: '35px',
                    }}
                />
                <Typography
                    sx={{
                        fontSize: '16px',
                        maxWidth: '500px',
                    }}
                >
                    {t('backoffice:confirmation.caption')}
                </Typography>
            </Stack>
            <Stack justifyContent="flex-end" gap="15px" direction="row">
                <Button color="error" variant="outlined" onClick={onReject}>
                    {t('backoffice:confirmation.reject')}
                </Button>
                <Button
                    sx={theme => ({
                        '& .MuiTypography-root': {
                            fontSize: '16px',
                            color: theme.palette.background.default,
                        },
                    })}
                    onClick={onApprove}
                >
                    {t('backoffice:confirmation.approve')}
                </Button>
            </Stack>
        </Stack>
    )
}
export default ResourceConfirmation

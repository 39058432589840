import { object, string } from 'yup'

import { emailValidator } from '../index.validators'

export interface LoginFormValues {
    email: string
    password: string
}

export const LoginFormInitialValues = {
    email: '',
    password: '',
}

export const LoginFormScheme = object({
    email: string().test(emailValidator).required('Обов`язкове поле'),
    password: string().required('Обов`язкове поле'),
})

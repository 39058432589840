export const extractSubdomain = (location: Location): string | null => {
    const URLSegmentsCount = process.env.NODE_ENV === 'production' ? 3 : 2

    const { host } = location
    const parts = host.split('.')
    if (parts.length === URLSegmentsCount) {
        return parts[0]
    }

    return null
}

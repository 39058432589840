import React, { JSX } from 'react'

import { useFormik } from 'formik'
import { Box, Stack, Typography, TextField } from '@mui/material'
import { t } from 'i18next'
import { LoadingButton } from '@mui/lab'

import { ModuleFormScheme, ModuleFormValues } from './index.schema'
import { useModulesAPI } from '@backoffice/hooks/modules'
import { CourseType, ModuleType } from 'api/root/generated'

interface ModuleFormProps {
    course: CourseType
    module?: ModuleType
    onSuccess?: (action: 'add' | 'update') => void
}

const ModuleForm = ({
    course,
    module,
    onSuccess,
}: ModuleFormProps): JSX.Element => {
    const { create, update, loading } = useModulesAPI()

    const formik = useFormik({
        initialValues: module
            ? {
                  name: module.name || '',
                  description: module.description || '',
              }
            : {
                  name: '',
                  description: '',
              },
        validationSchema: ModuleFormScheme,
        onSubmit: (values: ModuleFormValues) => {
            if (module) {
                update({
                    input: {
                        moduleId: module.id,
                        ...values,
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.('update')
                    }
                })
            } else {
                create({
                    input: {
                        courseId: course.id,
                        ...values,
                    },
                }).then(response => {
                    if (response?.success) {
                        onSuccess?.('add')
                    }
                })
            }
        },
    })

    return (
        <Box
            sx={{
                padding: '25px',
                width: '100%',
                maxWidth: '500px',
                minWidth: '500px',
                '@media (max-width:550px)': {
                    padding: '25px',
                },
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Stack gap="25px">
                    <Stack>
                        <Typography
                            sx={{
                                fontSize: '20px',
                            }}
                        >
                            {module
                                ? t(
                                      'backoffice:content.course.module.editTitle'
                                  )
                                : t(
                                      'backoffice:content.course.module.createTitle'
                                  )}
                        </Typography>
                        <Typography
                            sx={theme => ({
                                fontSize: '22px',
                                color: theme.palette.primary.main,
                            })}
                        >
                            {module ? module.name : course.name}
                        </Typography>
                    </Stack>
                    <TextField
                        fullWidth
                        autoComplete="off"
                        name="name"
                        variant="outlined"
                        label={t(
                            'backoffice:content.course.module.fields.name'
                        )}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.name && Boolean(formik.errors.name)
                        }
                    />
                    <TextField
                        multiline
                        rows={14}
                        fullWidth
                        autoComplete="off"
                        name="description"
                        variant="outlined"
                        label={t(
                            'backoffice:content.course.module.fields.description'
                        )}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.description &&
                            Boolean(formik.errors.description)
                        }
                    />
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        fullWidth
                        sx={{ padding: '15px' }}
                    >
                        {module
                            ? t('backoffice:content.course.module.actions.edit')
                            : t(
                                  'backoffice:content.course.module.actions.create'
                              )}
                    </LoadingButton>
                </Stack>
            </form>
        </Box>
    )
}

export default ModuleForm

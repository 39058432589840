import React, { useState } from 'react'

import {
    Button,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { QuestionMarkOutlined } from '@mui/icons-material'
import { t } from 'i18next'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/uk'

import { Image } from 'components'
import { LessonType } from 'api/root/generated'

interface EventModalProps {
    stream: string
    date: string
    type: 'add' | 'edit'
    lesson: LessonType
    onSubmit: (id: string, openAt: string) => void
    onCancel: () => void
}

const EventModal = ({
    lesson,
    onSubmit,
    onCancel,
    stream,
    type,
    date,
}: EventModalProps) => {
    console.log(date)
    const [openAt, setOpenAt] = useState<Dayjs | null>(dayjs(date))
    console.log(openAt)
    const matches700px = useMediaQuery('(max-width:700px)')

    return (
        <Stack
            padding="25px"
            gap="45px"
            sx={{
                minWidth: matches700px ? '350px' : '450px',
            }}
        >
            <Typography fontSize="18px">
                {t('backoffice:stream.form.title', { stream })}
            </Typography>
            <Stack direction="row" gap="25px" alignItems="center" width="100%">
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        boxShadow: '0 0 7px #000',
                        border: `1px solid transparent`,
                        cursor: 'pointer',
                        position: 'relative',
                        width: '160px',
                        height: '100px',
                    }}
                >
                    {lesson.videoPreview ? (
                        <Image
                            src={lesson.videoPreview.img150x100 as string}
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                            alt=""
                        />
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <QuestionMarkOutlined
                                sx={{
                                    color: '#4e4e4e',
                                    fontSize: '75px',
                                }}
                            />
                        </Stack>
                    )}
                </Stack>
                <Typography maxWidth="250px" fontSize="20px">
                    {lesson.name}
                </Typography>
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
                <DateTimePicker
                    label="Дата вiдкриття"
                    ampm={false}
                    onChange={date => {
                        const datetime = dayjs(date)
                        if (datetime.isValid()) {
                            setOpenAt(datetime)
                        }
                    }}
                    value={openAt}
                />
            </LocalizationProvider>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap="15px"
            >
                <Button color="secondary" variant="outlined" onClick={onCancel}>
                    {t('backoffice:stream.form.cancel')}
                </Button>
                <Button
                    onClick={() =>
                        onSubmit(lesson.id, openAt?.toISOString() as string)
                    }
                >
                    {type === 'add'
                        ? t('backoffice:stream.form.add')
                        : t('backoffice:stream.form.save')}
                </Button>
            </Stack>
        </Stack>
    )
}

export default EventModal

import React, { lazy } from 'react'

const ExpertPage = lazy(() => import('@storefront/components/pages/ExpertPage'))
const CoursePage = lazy(() => import('@storefront/components/pages/CoursePage'))
const WatchPage = lazy(() => import('@storefront/components/pages/WatchPage'))

const NotFoundPage = lazy(
    () => import('@storefront/components/pages/NotFoundPage')
)

const paths = {
    expert: '/',
    courses: '/courses',
    course: (courseId: string) => `/${courseId}`,
    module: (courseId: string, moduleId: string, lessonId: string) =>
        `/courses/${courseId}/${moduleId}/${lessonId}`,
    watch: (lessonId: string) => `/watch/${lessonId}`,
    backoffice: '/backoffice/*',
}

const routes = [
    {
        path: paths.expert,
        element: <ExpertPage />,
    },
    {
        path: paths.course(':courseId'),
        element: <CoursePage />,
    },
    {
        path: paths.watch(':lessonId'),
        element: <WatchPage />,
    },

    {
        path: '*',
        element: <NotFoundPage />,
    },
]

export { routes, paths }

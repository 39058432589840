import { styled } from '@mui/material/styles'

import Dialog, { DialogProps } from './Dialog'
import ReactiveDialog from './ReactiveDialog'
import Logo, { LogoProps } from './Logo'
import SocialsList, { SocialItem, Socials } from './SocialsList'
import ResourceConfirmation, {
    ResourceConfirmationProps,
} from './ResourceConfirmation'
import TextConfirmation, { TextConfirmationProps } from './TextConfirmation'
import FeedbackPreview from './FeedbackPreview'
import FeedbackSample from './FeedbackSample'
import FilePicker from './FilePicker'
import Player from './Player'
import Toast from './Toast'
import SelectField from './SelectField'
import PasswordField from './PasswordField'
import SearchField from './SearchField'
import ColorPicker from './ColorPicker'
import GlobalError from './GlobalError'
import Loader from './Loader'

const Image = styled('img')``
const Input = styled('input')``
const Form = styled('form')``
const Svg = styled('svg')``

export {
    Dialog,
    ReactiveDialog,
    Logo,
    SocialsList,
    ResourceConfirmation,
    TextConfirmation,
    FeedbackPreview,
    FeedbackSample,
    Image,
    Input,
    Form,
    Svg,
    FilePicker,
    Player,
    Toast,
    ColorPicker,
    SelectField,
    PasswordField,
    SearchField,
    GlobalError,
    Loader,
    Socials,
}

export type {
    DialogProps,
    SocialItem,
    ResourceConfirmationProps,
    TextConfirmationProps,
    LogoProps,
}

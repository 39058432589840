import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import {
    UserType,
    GetCoachProfileDocument,
    CourseType,
} from 'api/root/generated'

interface CoachProfile {
    user: UserType | null
    courses: CourseType[]
}

export const useCoachProfile = () => {
    const [response, setResponse] = useState<CoachProfile | null>({
        courses: [],
        user: null,
    })

    const { data, loading, error, refetch } = useQuery(GetCoachProfileDocument)

    useEffect(() => {
        if (data?.user && data?.courseList) {
            setResponse({
                user: data.user as UserType,
                courses: data.courseList.edges.map(
                    n => n?.node
                ) as CourseType[],
            })
        }
    }, [data])

    return {
        courses: response?.courses || [],
        user: response?.user,
        loading,
        error,
        refetch,
    }
}

import React from 'react'

import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client'

import { DialogProps } from 'components'

export interface ReactiveDialog<U> {
    component?: React.FC<U>
    props?: U
    options?: DialogProps
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DialogReactiveVar: ReactiveVar<ReactiveDialog<any>> = makeVar({})

interface UseDialogResult {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    open: ({ component, props, options }: ReactiveDialog<any>) => void
    close: () => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: ReactiveDialog<any>
}
const useDialog = (): UseDialogResult => {
    const data = useReactiveVar(DialogReactiveVar)
    const close = (): void => {
        DialogReactiveVar({})
    }

    const open = ({
        component,
        props: newProps,
        options: newOptions,
    }: ReactiveDialog<unknown>): void => {
        DialogReactiveVar({ component, props: newProps, options: newOptions })
    }

    return { open, close, data }
}

export default useDialog

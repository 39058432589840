import { object, string, mixed, boolean } from 'yup'
import { t } from 'i18next'

export interface LessonFormValues {
    name: string
    description: string
    videoUrl: boolean
}

export const LessonFormScheme = object({
    name: string().required(t('auth:errors.required-field') || ''),
    description: string(),
    videoUrl: boolean().oneOf(
        [true],
        t('backoffice:content.course.lesson.form.errors.noVideo') || ''
    ),
})

import React, { JSX, useState } from 'react'

import {
    Typography,
    Stack,
    Divider,
    TextField,
    InputAdornment,
} from '@mui/material'
import { t } from 'i18next'

import Layout from './Layout'
import { UserType } from 'api/root/generated'

interface AccountProps {
    user: UserType
}

const Profile = ({ user }: AccountProps): JSX.Element => {
    const [subdomain, setSubdomain] = useState(user?.subdomain)

    return (
        <Layout title={t('backoffice:settings.profile.title')}>
            <Stack gap="15px" divider={<Divider />}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack>
                        <Typography fontSize="22px">
                            {t('backoffice:settings.profile.subdomainLabel')}
                        </Typography>
                        <Typography
                            fontSize="17px"
                            sx={{ color: 'secondary.light' }}
                            variant="caption"
                        >
                            {t('backoffice:settings.profile.subdomainCaption')}
                        </Typography>
                    </Stack>
                    <TextField
                        placeholder="URL"
                        variant="outlined"
                        sx={{
                            width: '300px',
                        }}
                        value={subdomain}
                        onChange={e => setSubdomain(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    .sippincup.com
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
            </Stack>
        </Layout>
    )
}
export default Profile

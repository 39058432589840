import React, { JSX } from 'react'

import { Typography, Stack, useMediaQuery } from '@mui/material'
import { t } from 'i18next'

import { useMe } from 'hooks/user'
import { useDocumentTitle } from 'hooks/common'

const SettingsPage = (): JSX.Element => {
    useDocumentTitle(
        `${t('backoffice:pageTitles.backoffice')} | ${t(
            'backoffice:pageTitles.notificationsPage'
        )}`
    )

    const lessThan1400px = useMediaQuery('(max-width:1400px)')

    const { me } = useMe()
    const SCREEN_PADDING = lessThan1400px ? '5%' : '5% 10% 5%'

    const notifications = [
        {
            read: false,
            content:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae nisi numquam quidem vel? Aperiam debitis doloremque et ipsum molestias placeat sapiente veritatis! Aliquam, consectetur, non?',
        },
        {
            read: false,
            content:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae nisi numquam quidem vel? Aperiam debitis doloremque et ipsum molestias placeat sapiente veritatis! Aliquam, consectetur, non?',
        },
        {
            read: false,
            content:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae nisi numquam quidem vel? Aperiam debitis doloremque et ipsum molestias placeat sapiente veritatis! Aliquam, consectetur, non?',
        },
        {
            read: true,
            content:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae nisi numquam quidem vel? Aperiam debitis doloremque et ipsum molestias placeat sapiente veritatis! Aliquam, consectetur, non?',
        },
        {
            read: true,
            content:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae nisi numquam quidem vel? Aperiam debitis doloremque et ipsum molestias placeat sapiente veritatis! Aliquam, consectetur, non?',
        },
        {
            read: false,
            content:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae nisi numquam quidem vel? Aperiam debitis doloremque et ipsum molestias placeat sapiente veritatis! Aliquam, consectetur, non?',
        },
        {
            read: false,
            content:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae nisi numquam quidem vel? Aperiam debitis doloremque et ipsum molestias placeat sapiente veritatis! Aliquam, consectetur, non?',
        },
        {
            read: false,
            content:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae nisi numquam quidem vel? Aperiam debitis doloremque et ipsum molestias placeat sapiente veritatis! Aliquam, consectetur, non?',
        },
    ]

    const getRandomRotation = (min = -15, max = 15) => {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    const renderItem = (item: { content: string; read: boolean }) => {
        return (
            <Stack
                width="350px"
                justifyContent="space-between"
                alignItems="center"
                sx={theme => ({
                    background: '#181818',
                    padding: '25px',
                    borderRadius: '6px',
                    border: '1px solid rgba(255, 255, 255, 0.12)',
                    height: '500px',
                    position: 'relative',
                    boxShadow: '0 0 10px -6px #131313',
                    '&:hover': {
                        borderColor: theme.palette.primary.main,
                    },
                    ...(!item.read && {
                        transform: `rotate(${getRandomRotation(-2, 2)}deg)`,
                        boxShadow: '0 0 27px -6px #131313',
                    }),
                })}
            >
                <Typography fontSize="24px">{item.content}</Typography>
            </Stack>
        )
    }

    return (
        <Stack gap="35px" padding={SCREEN_PADDING}>
            <Typography fontSize="40px">
                {t('backoffice:notifications.title')}
            </Typography>
            <Stack gap="10px" direction="row" flexWrap="wrap">
                {notifications.map(renderItem)}
            </Stack>
        </Stack>
    )
}
export default SettingsPage

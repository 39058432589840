import React, { JSX } from 'react'

import { Typography, Stack, TextField, Button } from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { number, object, string } from 'yup'

import { Image } from 'components'
import stripeLogo from 'assets/images/stripe.png'

export interface StripeFormValues {
    paymentService: number
    payload: {
        secret_key: string
    }
}

interface StripeFormProps {
    paymentService: number
    onSubmit: (values: StripeFormValues) => void
}

const StripeForm = ({
    paymentService,
    onSubmit,
}: StripeFormProps): JSX.Element => {
    const formik = useFormik({
        initialValues: {
            paymentService,
            payload: {
                secret_key: '',
            },
        },
        validationSchema: object({
            paymentService: number(),
            payload: object({
                secret_key: string().required(
                    t('auth:errors.required-field') as string
                ),
            }),
        }),
        onSubmit,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack
                gap="25px"
                padding="25px"
                sx={{
                    width: '375px',
                }}
            >
                <Typography fontSize="22px">
                    {t('backoffice:settings.payment.forms.stripeTitle')}
                </Typography>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        padding: '20px 10px',
                        position: 'relative',
                        marginLeft: '-24px',
                        marginRight: '-24px',
                    }}
                >
                    <Image
                        src={stripeLogo}
                        sx={{
                            width: '320px',
                        }}
                    />
                </Stack>
                <Stack gap="25px">
                    <TextField
                        placeholder="Secret key"
                        variant="outlined"
                        fullWidth
                        name="payload.secret_key"
                        value={formik.values.payload.secret_key}
                        onChange={formik.handleChange}
                        error={
                            formik.touched?.payload?.secret_key &&
                            Boolean(formik.errors?.payload?.secret_key)
                        }
                        helperText={
                            formik.touched?.payload?.secret_key &&
                            formik.errors?.payload?.secret_key
                        }
                    />
                </Stack>
                <Stack gap="10px">
                    <LoadingButton variant="contained" type="submit">
                        {t('backoffice:settings.payment.forms.save')}
                    </LoadingButton>
                    <Button variant="outlined">
                        {t('backoffice:settings.payment.forms.cancel')}
                    </Button>
                </Stack>
            </Stack>
        </form>
    )
}
export default StripeForm

import React, { useRef, useState, MouseEvent } from 'react'

import {
    Box,
    Button,
    Checkbox,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material'
import {
    AccountCircle,
    ContentCopy,
    MoreVert,
    PublicRounded,
    CalendarMonth,
    CallSplitRounded,
    KeyboardArrowDown,
} from '@mui/icons-material'
import { t } from 'i18next'

import { CourseAccessType, CourseStreamType } from 'api/root/generated'
import { Image } from 'components'
import checkbox from 'assets/images/checkbox.svg'
import checkboxChecked from 'assets/images/checkbox-checked.svg'

interface AccessCardProps {
    access: Partial<CourseAccessType>
    onSelect: (email: string, selected: boolean) => void
    onOpen: (access: Partial<CourseAccessType>) => void
    onCopyAccessLink: (email: string) => void
    selected?: boolean
    streams: CourseStreamType[]
    // onChangeScopeOfStreamState: (access: Partial<CourseAccessType>) => void
    onStreamChange: (
        access: Partial<CourseAccessType>,
        streamId?: string | null
    ) => void
}

const AccessCard = ({
    access,
    onSelect,
    selected = false,
    onOpen,
    // onChangeScopeOfStreamState,
    onStreamChange,
    streams,
    onCopyAccessLink,
}: AccessCardProps) => {
    const matches700px = useMediaQuery('(max-width:700px)')
    const matches400px = useMediaQuery('(max-width:400px)')

    const user = access.client
    const checkboxRef = useRef<HTMLInputElement | null>(null)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Stack
            direction="row"
            padding={matches700px ? '8px' : '15px'}
            gap={matches700px ? '10px' : '15px'}
            alignItems="center"
            justifyContent="space-between"
            sx={theme => ({
                position: 'relative',
                cursor: 'pointer',
                backgroundColor: '#282828',
                boxShadow: '0 0 7px #000',
                border: `2px solid ${theme.palette.secondary.dark}`,
                ...(matches400px && {
                    width: '100%',
                }),
                '&:hover': {
                    border: `2px solid${theme.palette.primary.main}`,
                },
            })}
        >
            <Checkbox
                inputRef={checkboxRef}
                icon={<Image src={checkbox} />}
                checkedIcon={<Image src={checkboxChecked} />}
                checked={selected}
                onChange={(_, checked) => {
                    onSelect(access.client?.email as string, checked)
                }}
                onClick={e => e.stopPropagation()}
                sx={{
                    '&.Mui-disabled': {
                        opacity: '0.3',
                    },
                    padding: '5px',
                    '& > img': {
                        width: '24px',
                        height: '24px',
                        ...(matches700px && {
                            width: '18px',
                            height: '18px',
                        }),
                    },
                }}
            />

            <Stack>
                <Typography
                    noWrap
                    fontSize={matches700px ? '16px' : '20px'}
                    width={matches700px ? '160px' : '200px'}
                >
                    {user?.email}
                </Typography>
                {streams.length > 0 &&
                    (access.stream ? (
                        <Typography
                            color="#d6bf62"
                            noWrap
                            fontSize={matches700px ? '11px' : '14px'}
                            width={matches700px ? '160px' : '200px'}
                        >
                            {access.stream?.name}
                        </Typography>
                    ) : (
                        <Typography
                            color="#949494"
                            noWrap
                            fontSize={matches700px ? '11px' : '14px'}
                            width={matches700px ? '160px' : '200px'}
                        >
                            {t('backoffice:access.withoutStream')}
                        </Typography>
                    ))}
            </Stack>
            <Stack direction="row" gap="5px" alignItems="center">
                <Tooltip
                    title={
                        <Typography fontSize="18px" variant="caption">
                            {t('backoffice:access.checkAccesses')}
                        </Typography>
                    }
                >
                    <IconButton
                        onClick={e => {
                            e.stopPropagation()
                            onOpen(access)
                        }}
                    >
                        <PublicRounded
                            sx={{
                                fontSize: '21px',
                            }}
                        />
                    </IconButton>
                </Tooltip>

                <Tooltip
                    title={
                        <Typography fontSize="18px" variant="caption">
                            {t('backoffice:access.copyAccessLink')}
                        </Typography>
                    }
                >
                    <IconButton
                        onClick={e => {
                            e.stopPropagation()
                            onCopyAccessLink(access.client?.email as string)
                        }}
                    >
                        <ContentCopy
                            sx={{
                                fontSize: '21px',
                            }}
                        />
                    </IconButton>
                </Tooltip>

                {streams.length > 0 && (
                    <>
                        <Button
                            sx={{
                                minWidth: 0,
                                '& .MuiButton-startIcon': {
                                    marginRight: 0,

                                    '& .MuiSvgIcon-root': {
                                        fontSize: '25px',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        ...(access.stream && {
                                            color: 'white',
                                        }),
                                    },
                                },
                                '& .MuiButton-endIcon': {
                                    marginLeft: 0,
                                },
                            }}
                            color="secondary"
                            variant="text"
                            disableElevation
                            onClick={event => {
                                event.stopPropagation()
                                handleClick(event)
                            }}
                            endIcon={<KeyboardArrowDown />}
                            startIcon={<CallSplitRounded />}
                        />
                        <Menu
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            {access.stream ? (
                                <>
                                    <MenuItem
                                        disableRipple
                                        onClick={() => {
                                            onStreamChange(access, null)
                                            handleClose()
                                        }}
                                    >
                                        {t(
                                            'backoffice:access.removeFromStream'
                                        )}
                                    </MenuItem>
                                    <Divider />
                                </>
                            ) : (
                                <MenuItem disableRipple disabled>
                                    {t('backoffice:access.addToStream')}
                                </MenuItem>
                            )}

                            {streams.map(s => (
                                <MenuItem
                                    key={s.id}
                                    sx={{
                                        width: '200px',
                                    }}
                                    selected={s.id === access.stream?.id}
                                    value={s.id}
                                    disableRipple
                                    onClick={() => {
                                        onStreamChange(access, s.id)
                                        handleClose()
                                    }}
                                >
                                    <Typography noWrap>{s.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                )}
            </Stack>
        </Stack>
    )
}

export default AccessCard

import React, { JSX, useState } from 'react'

import {
    Typography,
    Stack,
    Button,
    Box,
    useMediaQuery,
    IconButton,
} from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
    DoneAll,
    DoNotDisturb,
    ForwardToInbox,
    SupervisedUserCircle,
} from '@mui/icons-material'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { useCourse } from 'hooks/courses'
import { LessonType, ModuleType } from 'api/root/generated'
import Module from './components/Module'
import { useAccessAPI } from '@backoffice/hooks/accesses'
import { paths } from '@backoffice/index.routes'
import { useDocumentTitle } from '../../../../../hooks/common'

const CourseGrantAccessPage = (): JSX.Element => {
    const matches1200px = useMediaQuery('(max-width:1200px)')
    const matches800px = useMediaQuery('(max-width:800px)')
    const matches700px = useMediaQuery('(max-width:700px)')

    const { courseId } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const { course, error } = useCourse(courseId as string)

    useDocumentTitle(
        `${t('backoffice:pageTitles.grantAccessPage')} | ${course?.name}`
    )

    const { grantLessonAccess, loading } = useAccessAPI()

    const [selectedLessons, setSelectedLessons] = useState<string[]>([])

    const handleSelectAllLessons = () => {
        const allModules = course?.modules?.edges.map(
            e => e?.node
        ) as ModuleType[]

        const allLessons = allModules
            .map(m => m.lessons.edges.map(e => e?.node) as LessonType[])
            .flat()
            .map(l => l?.id as string)

        setSelectedLessons(allLessons)
    }

    const handleCancelSelection = () => {
        setSelectedLessons([])
    }

    const handleSelectLesson = (lesson: LessonType, selected: boolean) => {
        if (selected) {
            setSelectedLessons(prevState => [...prevState, lesson.id])
        } else {
            setSelectedLessons(prevState =>
                prevState.filter(id => id !== lesson.id)
            )
        }
    }

    const handleSelectModule = (module: ModuleType, selected: boolean) => {
        if (selected) {
            const lessonsToSelect = module.lessons.edges
                .map(e => e?.node)
                .map(l => l?.id as string)

            setSelectedLessons(prevState => {
                return [
                    ...prevState,
                    ...lessonsToSelect.filter(l => !prevState.includes(l)),
                ]
            })
        } else {
            const lessonsToDeselect = module.lessons.edges
                .map(e => e?.node)
                .map(l => l?.id as string)

            setSelectedLessons(prevState =>
                prevState.filter(id => !lessonsToDeselect.includes(id))
            )
        }
    }

    const allLessonsCount = (
        course?.modules?.edges.map(e => e?.node) as ModuleType[]
    )
        ?.map(m => m.lessons.edges.map(e => e?.node) as LessonType[])
        .flat().length

    const isAllLessonsSelected = allLessonsCount === selectedLessons.length

    const emails = JSON.parse(searchParams.get('emails') || '[]')

    const handleGrantAccess = (lessons: string[]) => {
        grantLessonAccess({
            input: {
                courseId: courseId as string,
                lessonIds: lessons,
                clientEmails: emails,
                redirectTo: `${window.location.origin}/${course?.id}`,
            },
        }).then(response => {
            if (response?.success) {
                enqueueSnackbar(
                    t(
                        'backoffice:access.grant.notifications.accessUpdateSuccess'
                    )
                )
                navigate(`../${paths.courseAccess(courseId as string)}`)
            }
        })
    }

    return (
        <Stack gap="25px" padding={matches1200px ? '10px' : '1% 2%'}>
            <Stack
                gap="15px"
                direction="column"
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <Stack direction="column" alignItems="flex-start" gap="10px">
                    <Stack direction="row" alignItems="center" gap="15px">
                        <SupervisedUserCircle
                            sx={{
                                fontSize: matches700px ? '55px' : '70px',
                                color: '#d6bf62',
                            }}
                        />
                        <Stack>
                            <Typography fontSize="max(min(2vw, 20px), 17px)">
                                {emails.join(', ')}
                            </Typography>
                            <Typography
                                fontSize="max(min(2vw, 18px), 14px)"
                                sx={theme => ({ color: '#949494' })}
                            >
                                {t(
                                    'backoffice:access.grant.grantAccessForClients',
                                    {
                                        count: emails.length,
                                    }
                                )}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="15px"
                    sx={{
                        alignSelf: 'flex-end',
                        '& .MuiButton-root': {
                            fontSize: '16px',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: matches800px
                                ? '18px'
                                : matches1200px
                                ? '22px'
                                : '28px',
                        },
                    }}
                >
                    {selectedLessons.length > 0 && (
                        <Typography fontSize="18px" marginRight="20px">
                            {t('backoffice:access.grant.selectedCount', {
                                count: selectedLessons.length,
                            })}
                        </Typography>
                    )}

                    {matches700px ? (
                        <IconButton
                            color="secondary"
                            sx={theme => ({
                                border: `1px solid`,
                                borderColor: 'secondary',
                                borderRadius: '4px',
                            })}
                            onClick={
                                isAllLessonsSelected
                                    ? handleCancelSelection
                                    : handleSelectAllLessons
                            }
                        >
                            {isAllLessonsSelected ? (
                                <DoNotDisturb color="error" />
                            ) : (
                                <DoneAll />
                            )}
                        </IconButton>
                    ) : (
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={
                                isAllLessonsSelected ? (
                                    <DoNotDisturb color="error" />
                                ) : (
                                    <DoneAll />
                                )
                            }
                            onClick={
                                isAllLessonsSelected
                                    ? handleCancelSelection
                                    : handleSelectAllLessons
                            }
                        >
                            {isAllLessonsSelected
                                ? t('backoffice:access.grant.deselectAll')
                                : t('backoffice:access.grant.selectAll')}
                        </Button>
                    )}

                    {matches700px ? (
                        <IconButton
                            disabled={selectedLessons.length === 0 || loading}
                            color="success"
                            sx={theme => ({
                                border: `1px solid`,
                                borderColor: 'secondary',
                                borderRadius: '4px',
                            })}
                            onClick={() => handleGrantAccess(selectedLessons)}
                        >
                            <ForwardToInbox />
                        </IconButton>
                    ) : (
                        <Button
                            disabled={selectedLessons.length === 0 || loading}
                            variant="outlined"
                            color="success"
                            startIcon={<ForwardToInbox />}
                            onClick={() => handleGrantAccess(selectedLessons)}
                        >
                            {t('backoffice:access.grant.shareAccess')}
                        </Button>
                    )}
                </Stack>
            </Stack>
            <Box
                sx={{
                    height: '2px',
                    position: 'relative',
                    zIndex: 1,
                    background:
                        'linear-gradient(90deg, rgba(228,217,217,0) 10%, rgba(163,163,163,1) 100%)',
                }}
            />
            <Box>
                {course?.modules?.edges
                    .map(e => e?.node as ModuleType)
                    .map(m => (
                        <Module
                            selectedLessons={selectedLessons}
                            onSelectLesson={handleSelectLesson}
                            onSelectModule={handleSelectModule}
                            key={m?.id}
                            module={m}
                        />
                    ))}
            </Box>
        </Stack>
    )
}
export default CourseGrantAccessPage

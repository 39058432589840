import React, { JSX } from 'react'

import { t } from 'i18next'
import { Typography, Stack } from '@mui/material'

import { Logo } from 'components'
import { useDocumentTitle } from 'hooks/common'

const NotFoundPage = (): JSX.Element => {
    useDocumentTitle(t('backoffice:pageTitles.notFoundPage'))

    return (
        <Stack
            height="100vh"
            alignItems="center"
            gap="40px"
            paddingTop="15%"
            sx={{
                display: 'flex',
                flexGrow: 1,
                width: '100%',
            }}
        >
            <Logo noColor />
            <Typography>{t('common:errors.notFoundPage')}</Typography>
        </Stack>
    )
}

export default NotFoundPage

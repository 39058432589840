import React, { JSX, useEffect, useState } from 'react'

import FullCalendar from '@fullcalendar/react'
import {
    EventDropArg,
    EventInput,
    MoreLinkContentArg,
} from '@fullcalendar/core'
import ukLocale from '@fullcalendar/core/locales/uk'
import interactionPlugin, {
    DateClickArg,
    EventReceiveArg,
} from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import {
    Box,
    Divider,
    LinearProgress,
    Menu,
    MenuItem,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { CallSplitRounded, QuestionMarkOutlined } from '@mui/icons-material'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import { useCourse } from 'hooks/courses'
import Actions from './components/Actions'
import EventModal from './components/EventModal'
import { CourseStreamType, LessonType } from 'api/root/generated'
import { useDialog, useDocumentTitle } from 'hooks/common'
import { Image } from '../../../../../components'
import { CourseStreamForm } from '../../forms'
import { useStreams, useStreamsAPI } from '@backoffice/hooks/streams'
import { useConfirmationDialog } from '@backoffice/hooks/common'

interface EventLikeObject {
    image: string
    title: string
    time?: string
}

const CourseStreamPage = (): JSX.Element => {
    const matches1200px = useMediaQuery('(max-width:1200px)')
    const matches700px = useMediaQuery('(max-width:700px)')
    const { enqueueSnackbar } = useSnackbar()
    const { requestTextConfirmation } = useConfirmationDialog()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [addToDate, setAddToDate] = useState<string | null>(null)

    const handleClose = () => {
        setAnchorEl(null)
    }

    const [selectedStream, setSelectedStream] =
        useState<CourseStreamType | null>(null)
    const [events, setEvents] = useState<EventInput[]>([])

    const { open, close } = useDialog()
    const { courseId } = useParams()

    const { course, refetch } = useCourse(courseId as string)

    useDocumentTitle(
        `${t('backoffice:pageTitles.streamPage')} | ${course?.name}`
    )

    const {
        streams,
        refetch: refetchStreams,
        loading,
    } = useStreams({
        courseId: courseId as string,
    })
    const { update, remove } = useStreamsAPI()

    const modules = course?.modules?.edges.map(n => n?.node)
    const lessons = modules
        ?.map(m => m?.lessons.edges.map(e => e?.node))
        .flat() as LessonType[]
    const unassignedLessons = lessons?.filter(l => {
        const schedule = JSON.parse(selectedStream?.schedule || '{}')
        return schedule ? !schedule[l.id] : false
    })

    useEffect(() => {
        if (selectedStream) {
            const schedule = JSON.parse(selectedStream.schedule)

            const events = lessons
                ?.filter(l => schedule[l.id])
                .map(l => ({
                    id: l?.id as string,
                    title: l?.name as string,
                    start: schedule[l.id],
                    extendedProps: {
                        image: l.videoPreview?.img60x40,
                    },
                }))
            setEvents(events)
        }
    }, [selectedStream])

    const handleEventReceive = (info: EventReceiveArg) => {
        setEvents([...events, info.event as EventInput])
    }

    const updateSchedule = (
        current: string,
        { id, openAt }: { id: string; openAt: string }
    ) => {
        const updatedSchedule: Record<string, string> = {}
        const currentSchedule = JSON.parse(current)

        // eslint-disable-next-line no-restricted-syntax
        for (const lessonId of lessons.map(l => l.id)) {
            if (lessonId === id) {
                updatedSchedule[lessonId] = openAt
            } else if (currentSchedule[lessonId]) {
                updatedSchedule[lessonId] = currentSchedule[lessonId]
            }
        }

        return JSON.stringify(updatedSchedule)
    }

    const handleSaveLessonData = (id: string, openAt: string) => {
        const updatedSchedule = updateSchedule(selectedStream?.schedule, {
            id,
            openAt,
        })

        update({
            input: {
                name: selectedStream?.name,
                streamId: selectedStream?.id as string,
                schedule: updatedSchedule,
            },
        }).then(response => {
            if (response?.success) {
                setSelectedStream({
                    ...(selectedStream as CourseStreamType),
                    schedule: updatedSchedule,
                })
                close()
                enqueueSnackbar(
                    t('backoffice:stream.notifications.lessonUpdateSuccess'),
                    { variant: 'success', preventDuplicate: true }
                )
                refetch()
                refetchStreams()
                setAddToDate(null)
            }
        })
    }

    const handleOpenStreamForm = (
        stream?: CourseStreamType,
        action: 'add' | 'edit' = 'add'
    ) => {
        open({
            component: CourseStreamForm,
            props: {
                stream,
                courseId,
                onCancel: close,
                onSuccess: () => {
                    const message =
                        action === 'add'
                            ? 'backoffice:stream.notifications.streamCreateSuccess'
                            : 'backoffice:stream.notifications.streamUpdateSuccess'
                    enqueueSnackbar(t(message), { variant: 'success' })
                    close()
                    refetchStreams()
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleDeleteStream = (id: string) => {
        remove({
            input: {
                streamId: id,
            },
        }).then(response => {
            if (response?.success) {
                enqueueSnackbar(
                    t('backoffice:stream.notifications.streamDeleteSuccess'),
                    { variant: 'success' }
                )
                close()
                refetchStreams()
                setSelectedStream(null)
            }
        })
    }

    const handleEditEvent = (id: string, type: 'add' | 'edit' = 'edit') => {
        const date = JSON.parse(selectedStream?.schedule)[id] || null

        open({
            component: EventModal,
            props: {
                type,
                lesson: lessons.find(l => l.id === id),
                date: type === 'add' ? addToDate : date,
                onCancel: close,
                onSubmit: handleSaveLessonData,
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleCellClick = (info: DateClickArg) => {
        setAnchorEl(info.dayEl)
        const { date } = info
        date.setHours(20)
        date.setMinutes(0)
        setAddToDate(date.toISOString())
    }

    const handleEventDrop = (info: EventDropArg) => {
        handleSaveLessonData(
            info.event.id,
            info.event.start?.toISOString() as string
        )
    }

    const renderEvent = ({ image, time, title }: EventLikeObject) => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                gap="15px"
                divider={<Divider orientation="vertical" flexItem />}
            >
                {time && <Typography>{time}</Typography>}
                <Stack direction="row" gap="10px" alignItems="center">
                    <Stack
                        maxWidth="100%"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: '40px',
                            height: '25px',
                            boxShadow: '0 0 3px #000',
                            border: `1px solid transparent`,
                        }}
                    >
                        {image ? (
                            <Image
                                src={image as string}
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                }}
                                alt=""
                            />
                        ) : (
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <QuestionMarkOutlined
                                    sx={{
                                        color: '#4e4e4e',
                                        fontSize: '20px',
                                    }}
                                />
                            </Stack>
                        )}
                    </Stack>
                    <Typography noWrap maxWidth="180px">
                        {title}
                    </Typography>
                </Stack>
            </Stack>
        )
    }

    const renderMore = ({ num }: MoreLinkContentArg) => (
        <Typography color="primary">
            {t('backoffice:stream.moreItems', {
                count: num,
            })}
        </Typography>
    )

    const renderCalendar = (stream: CourseStreamType) => {
        return (
            <FullCalendar
                titleFormat={{ year: 'numeric', month: 'long' }}
                height="100%"
                locale={ukLocale}
                dayMaxEvents={2}
                events={events}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                droppable
                editable
                eventDrop={handleEventDrop}
                eventReceive={handleEventReceive}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false,
                }}
                eventContent={arg => {
                    return renderEvent({
                        image: arg.event.extendedProps.image,
                        title: arg.event.title,
                        time: arg.timeText,
                    })
                }}
                eventClick={arg => handleEditEvent(arg.event.id)}
                dateClick={handleCellClick}
                moreLinkContent={renderMore}
            />
        )
    }

    const renderNoStreamSelected = () => (
        <Stack
            alignItems="center"
            justifyContent="center"
            gap="25px"
            paddingTop="10%"
        >
            <CallSplitRounded
                sx={{
                    color: 'secondary.light',
                    fontSize: matches700px ? '55px' : '70px',
                }}
            />
            <Typography
                sx={theme => ({ color: '#949494' })}
                fontSize="max(min(1vw, 26px), 15px)"
            >
                {t('backoffice:stream.noStreamSelected')}
            </Typography>
        </Stack>
    )

    if (loading) {
        return <LinearProgress />
    }

    return (
        <Stack gap="25px" padding={matches1200px ? '10px' : '1% 2%'}>
            <Stack
                gap="15px"
                direction="column"
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <Stack direction="column" alignItems="flex-start" gap="10px">
                    <Stack direction="row" alignItems="center" gap="15px">
                        <CallSplitRounded
                            sx={{
                                fontSize: matches700px ? '55px' : '70px',
                                color: '#d6bf62',
                            }}
                        />
                        <Stack>
                            <Typography fontSize="max(min(2vw, 20px), 17px)">
                                {course?.name}
                            </Typography>
                            <Typography
                                fontSize="max(min(2vw, 18px), 14px)"
                                sx={theme => ({ color: '#949494' })}
                            >
                                {t('backoffice:stream.title')}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Actions
                    selected={selectedStream}
                    onCreate={() => handleOpenStreamForm()}
                    onDelete={stream =>
                        requestTextConfirmation(
                            t('backoffice:stream.deleteConfirmTitle'),
                            t('backoffice:stream.deleteConfirmMessage'),
                            () => handleDeleteStream(stream.id)
                        )
                    }
                    onEdit={stream => handleOpenStreamForm(stream, 'edit')}
                    streams={streams}
                    onStreamChange={setSelectedStream}
                />
            </Stack>
            <Box
                sx={{
                    height: '2px',
                    position: 'relative',
                    zIndex: 1,
                    background:
                        'linear-gradient(90deg, rgba(228,217,217,0) 10%, rgba(163,163,163,1) 100%)',
                }}
            />
            <Stack
                direction="row"
                gap="25px"
                divider={<Divider />}
                height="100%"
            >
                <Stack width="100%" minHeight="920px">
                    {selectedStream
                        ? renderCalendar(selectedStream)
                        : renderNoStreamSelected()}
                </Stack>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    onClick={handleClose}
                    sx={{
                        '& .MuiPaper-root': {
                            maxHeight: '250px',
                        },
                    }}
                    transformOrigin={{
                        horizontal: 'center',
                        vertical: 'center',
                    }}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'center',
                    }}
                >
                    <MenuItem disabled>
                        {unassignedLessons?.length > 0
                            ? t('backoffice:stream.addEvent')
                            : t('backoffice:stream.noEventsToAdd')}
                    </MenuItem>

                    {unassignedLessons?.map(l => (
                        <MenuItem
                            key={l.id}
                            onClick={() => handleEditEvent(l.id, 'add')}
                            sx={{
                                padding: '10px 16px',
                            }}
                        >
                            {renderEvent({
                                image: l.videoPreview?.img60x40 as string,
                                title: l.name,
                            })}
                        </MenuItem>
                    ))}
                </Menu>
            </Stack>
        </Stack>
    )
}
export default CourseStreamPage

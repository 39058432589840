import { createTheme } from '@mui/material/styles'

const palette = {
    primary: {
        main: 'rgb(249 98 98)',
        light: '#e2d5f6',
        dark: 'rgb(199,83,83)',
    },
    secondary: {
        main: '#f0f0f0',
        dark: '#3a3a3a',
        light: '#706f6f',
    },
    error: { main: '#fa6d6d' },
    success: { main: '#43cc5a' },
    background: { default: '#181818', paper: 'rgba(122,122,122,0.77)' },
}

const theme = createTheme({
    palette: {
        ...palette,
        mode: 'dark',
    },
    breakpoints: {
        values: {
            xs: 500,
            sm: 700,
            md: 900,
            lg: 1200,
            xl: 1440,
        },
    },
    typography: {
        fontFamily: 'Roboto',
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    '&.MuiFormControl-root': {
                        gap: '5px',
                    },
                    '& .MuiInputAdornment-root': {
                        color: palette.secondary.main,
                    },
                    '& .MuiFormHelperText-root': {
                        '&.Mui-error': {
                            padding: '3px 5px',
                            fontSize: '13px',
                            background: palette.error.main,
                            color: '#fff',
                            fontWeight: 700,
                            margin: 0,
                        },
                    },
                    '& .Mui-disabled': {
                        opacity: '0.5',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    fontWeight: 700,
                    textTransform: 'none',
                    color: '#fff',
                    fontSize: '18px',
                    '&.MuiButton-textPrimary': {
                        color: palette.secondary.main,
                    },
                },
            },
        },
    },
})

export default theme

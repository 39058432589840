import React from 'react'

import { Stack, Typography } from '@mui/material'
import { t } from 'i18next'

import { CourseType } from 'api/root/generated'

interface GeneralProps {
    courses: CourseType[]
}

const General = ({ courses }: GeneralProps) => {
    const viewsCount =
        courses?.reduce((acc, c) => {
            return acc + Number(c?.viewCount)
        }, 0) || 0

    const accessCount =
        courses?.reduce((acc, c) => {
            return acc + Number(c?.accessCount)
        }, 0) || 0

    const lessonCount =
        courses?.reduce((acc, c) => {
            return acc + Number(c?.lessonCount)
        }, 0) || 0

    return (
        <Stack
            gap="20px"
            sx={{
                flexGrow: 1,
                padding: '25px',
                borderRadius: '6px',
                border: '1px solid rgba(255, 255, 255, 0.12)',
            }}
        >
            <Typography fontSize="17px" color="#9c9c9c">
                {t('backoffice:content.general.title')}
            </Typography>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    '& > *': {
                        flexBasis: '50%',
                    },
                }}
            >
                <Typography color="secondary.light">
                    {t('backoffice:content.general.courseCount', {
                        count: courses?.length,
                    })}
                </Typography>
                <Typography color="secondary.light">
                    {t('backoffice:content.general.lessonCount', {
                        count: lessonCount,
                    })}
                </Typography>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                    '& > *': {
                        flexBasis: '50%',
                    },
                }}
            >
                <Typography color="secondary.light">
                    {t('backoffice:content.general.viewCount', {
                        count: viewsCount,
                    })}
                </Typography>
                <Typography color="secondary.light">
                    {t('backoffice:content.general.accessCount', {
                        count: accessCount,
                    })}
                </Typography>
            </Stack>
        </Stack>
    )
}

export default General

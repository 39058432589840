import React, { JSX } from 'react'

import { Typography, Stack, Zoom, useMediaQuery } from '@mui/material'
import { t } from 'i18next'
import { Masonry } from '@mui/lab'
import { useNavigate } from 'react-router-dom'

import { Image } from 'components'
import { paths } from '@backoffice/index.routes'
import statistics from 'assets/images/statistics.jpg'
import content from 'assets/images/content.jpg'
import clients from 'assets/images/clients.jpg'
import feedback from 'assets/images/feedback.jpg'
import promotion from 'assets/images/promotion.jpg'
import { useDocumentTitle } from '../../../../../hooks/common'

const DashboardPage = (): JSX.Element => {
    useDocumentTitle(t('backoffice:pageTitles.dashboardPage'))

    const lessThan1400px = useMediaQuery('(max-width:1400px)')
    const lessThan800px = useMediaQuery('(max-width:800px)')

    const navigate = useNavigate()

    const renderPlate = (
        title: string,
        height: string,
        image: string,
        path: string,
        disabled = false
    ) => {
        return (
            <Zoom in>
                <Stack
                    onClick={() => {
                        if (!disabled) {
                            navigate(path)
                        }
                    }}
                    sx={theme => ({
                        boxSizing: 'border-box',
                        height,
                        padding: '25px 0 0 0',
                        border: '4px solid #181818',
                        cursor: 'pointer',
                        overflow: 'hidden',
                        position: 'relative',
                        boxShadow: '0 0 19px -6px #000',
                        ...(disabled && {
                            pointerEvents: 'none',
                            filter: 'grayscale(1) opacity(75%)',
                        }),
                        '& .MuiTypography-root': {
                            background:
                                'linear-gradient(90deg, rgba(24,24,24,0.8) 0%, rgba(24,24,24,1) 20%, rgba(24,24,24,0) 100%)',
                        },
                        '&:hover': {
                            border: `4px solid${theme.palette.primary.main}`,
                            '& .MuiTypography-root': {
                                color: `${theme.palette.primary.main}`,
                                background:
                                    'linear-gradient(90deg, rgba(24,24,24,1) 0%, rgba(24,24,24,0) 100%)',
                            },
                        },
                    })}
                >
                    <Image
                        src={image}
                        alt="preview"
                        sx={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            position: 'absolute',
                            zIndex: 0,
                        }}
                    />
                    <Typography
                        fontSize="max(min(3.5vw, 72px), 36px)"
                        sx={{
                            textShadow: '3px 3px 3px #000',
                            letterSpacing: '-0.1em',
                            paddingLeft: '20px',
                            zIndex: 1,
                        }}
                    >
                        {title}
                    </Typography>
                </Stack>
            </Zoom>
        )
    }

    const SCREEN_PADDING = lessThan1400px ? '5%' : '5% 15% 5%'
    const BIG_PLATE_HEIGHT = lessThan800px
        ? '150px'
        : lessThan1400px
        ? '400px'
        : '500px'
    const SMALL_PLATE_HEIGHT = lessThan800px
        ? '150px'
        : lessThan1400px
        ? '200px'
        : '250px'

    return (
        <Stack
            padding={SCREEN_PADDING}
            className="content"
            width="100%"
            alignItems="center"
            justifyContent="center"
        >
            <Masonry columns={lessThan800px ? 1 : 2} spacing={0}>
                {renderPlate(
                    t('backoffice:plates.content'),
                    BIG_PLATE_HEIGHT,
                    content,
                    paths.content
                )}
                {renderPlate(
                    t('backoffice:plates.feedback'),
                    SMALL_PLATE_HEIGHT,
                    feedback,
                    paths.feedback,
                    true
                )}
                {renderPlate(
                    t('backoffice:plates.clients'),
                    SMALL_PLATE_HEIGHT,
                    clients,
                    paths.settings,
                    true
                )}

                {renderPlate(
                    t('backoffice:plates.statistics'),
                    SMALL_PLATE_HEIGHT,
                    statistics,
                    paths.statistics,
                    true
                )}
                {renderPlate(
                    t('backoffice:plates.promotion'),
                    SMALL_PLATE_HEIGHT,
                    promotion,
                    paths.promotion,
                    true
                )}
            </Masonry>
        </Stack>
    )
}
export default DashboardPage

import React, { JSX, useState } from 'react'

import {
    Typography,
    Stack,
    Divider,
    TextField,
    Tooltip,
    Button,
} from '@mui/material'
import { Verified, NewReleases, Launch } from '@mui/icons-material'
import { t } from 'i18next'

import Layout from './Layout'
import { UserType } from 'api/root/generated'

interface AccountProps {
    user: UserType
}

const Account = ({ user }: AccountProps): JSX.Element => {
    const [email, setEmail] = useState(user?.email)
    const [password, setPassword] = useState('')
    const [selectedTariff] = useState('pro')

    return (
        <Layout
            title={t('backoffice:settings.account.title')}
            icon={
                user?.isEmailConfirmed ? (
                    <Tooltip
                        title={
                            <Typography fontSize="18px">
                                {t('backoffice:settings.account.verified')}
                            </Typography>
                        }
                    >
                        <Verified
                            sx={theme => ({
                                color: theme.palette.success.main,
                            })}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip
                        title={
                            <Typography fontSize="18px">
                                {t('backoffice:settings.account.notVerified')}
                            </Typography>
                        }
                    >
                        <NewReleases
                            sx={theme => ({ color: theme.palette.error.main })}
                        />
                    </Tooltip>
                )
            }
        >
            <Stack gap="25px" divider={<Divider />}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack>
                        <Typography fontSize="22px">
                            {t(
                                'backoffice:settings.account.selectedTariffLabel'
                            )}
                        </Typography>
                        <Stack gap="5px" direction="row">
                            <Typography
                                fontSize="17px"
                                sx={{ color: 'secondary.light' }}
                                variant="caption"
                            >
                                {t(
                                    'backoffice:settings.account.selectedTariffCaption'
                                )}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Button
                        variant="text"
                        endIcon={<Launch />}
                        sx={theme => ({
                            color:
                                selectedTariff === 'basic'
                                    ? 'secondary.main'
                                    : selectedTariff === 'advanced'
                                    ? 'primary.main'
                                    : '#d6bf62',
                            fontSize: '18px',
                        })}
                    >
                        {t(
                            `backoffice:settings.account.tariffs.${selectedTariff}`
                        )}
                    </Button>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack>
                        <Typography fontSize="22px">
                            {t('backoffice:settings.account.emailLabel')}
                        </Typography>
                        <Typography
                            fontSize="17px"
                            sx={{ color: 'secondary.light' }}
                            variant="caption"
                        >
                            {t('backoffice:settings.account.emailCaption')}
                        </Typography>
                    </Stack>
                    <TextField
                        variant="outlined"
                        sx={{
                            width: '300px',
                        }}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack>
                        <Typography fontSize="22px">
                            {t('backoffice:settings.account.passwordLabel')}
                        </Typography>
                        <Typography
                            fontSize="17px"
                            sx={{ color: 'secondary.light' }}
                            variant="caption"
                        >
                            {t('backoffice:settings.account.passwordCaption')}
                        </Typography>
                    </Stack>
                    <TextField
                        placeholder="********"
                        variant="outlined"
                        sx={{
                            width: '300px',
                        }}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </Stack>
            </Stack>
        </Layout>
    )
}
export default Account

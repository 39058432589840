import { JSX } from 'react'

import { Button, DialogActions } from '@mui/material'

interface DialogButtonsProps {
    onSubmit: () => void
    onCancel: () => void
}
const DialogButtons = ({
    onSubmit,
    onCancel,
}: DialogButtonsProps): JSX.Element => {
    return (
        <DialogActions
            sx={{
                justifyContent: 'flex-end',
                padding: '24px',
            }}
        >
            <Button size="small" onClick={onSubmit}>
                Done
            </Button>
            <Button variant="outlined" size="small" onClick={onCancel}>
                Cancel
            </Button>
        </DialogActions>
    )
}
export default DialogButtons

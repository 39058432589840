import { useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { ActivateAccountForm } from '../../forms'
import { Loader } from 'components'
import { Credentials, useTokenVerification } from '@auth/hooks/verification'
import { useMe } from 'hooks/user'
import { ROLES } from 'config/user.config'
import { useDocumentTitle } from 'hooks/common'

const AccountActivationPage = () => {
    useDocumentTitle(t('auth:pageTitles.accountActivation'))

    const [searchParams] = useSearchParams()
    const { role } = useMe()

    const [credentials, setCredentials] = useState<Credentials | undefined>()
    const [isLoading, setLoading] = useState(true)

    const redirectTo = searchParams.get('redirectTo')
    const email = searchParams.get('email')

    useTokenVerification({
        uid: searchParams.get('uid64'),
        token: searchParams.get('token'),
        onSuccess: setCredentials,
        onFinish: () => setLoading(false),
        // TODO: Make TOKEN verification enabled
        skip: true,
    })

    useEffect(() => {
        if (role !== ROLES.GUEST) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.location = redirectTo
        }
    }, [])

    if (isLoading) {
        return <Loader />
    }

    const handleSuccessActivation = () => {
        if (redirectTo) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.location = redirectTo
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.location = window.location.origin
        }
    }

    const renderExpiredLayout = () => {
        return (
            <Typography fontSize="18px" color="primary" textAlign="center">
                {t('auth:titles.expiredLink')}
            </Typography>
        )
    }

    return (
        <Box
            sx={{
                width: '100%',
                padding: '25px',
            }}
        >
            {credentials ? (
                <ActivateAccountForm
                    email={email}
                    onSuccess={handleSuccessActivation}
                />
            ) : (
                renderExpiredLayout()
            )}
        </Box>
    )
}

export default AccountActivationPage

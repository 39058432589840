import React, { JSX } from 'react'

import { Typography, Stack, TextField, Button } from '@mui/material'
import { t } from 'i18next'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { number, object, string } from 'yup'

import { Image } from 'components'
import { UserType } from 'api/root/generated'
import wayForPayLogo from 'assets/images/wayforpay.png'

export interface WayForPayFormValues {
    paymentService: number
    payload: {
        merchant_login: string
        merchant_secret_key: string
    }
}

interface WayForPayFormProps {
    paymentService: number
    onSubmit: (values: WayForPayFormValues) => void
}

const WayForPayForm = ({
    paymentService,
    onSubmit,
}: WayForPayFormProps): JSX.Element => {
    const formik = useFormik({
        initialValues: {
            paymentService,
            payload: {
                merchant_login: '',
                merchant_secret_key: '',
            },
        },
        validationSchema: object({
            paymentService: number(),
            payload: object({
                merchant_login: string().required(
                    t('auth:errors.required-field') as string
                ),
                merchant_secret_key: string().required(
                    t('auth:errors.required-field') as string
                ),
            }),
        }),
        onSubmit,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack
                gap="25px"
                padding="25px"
                sx={{
                    width: '375px',
                }}
            >
                <Typography fontSize="22px">
                    {t('backoffice:settings.payment.forms.wayForPayTitle')}
                </Typography>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        boxShadow: '0 0 7px #000',
                        border: `2px solid #000`,
                        padding: '20px 10px',
                        background: '#e5e5e5',
                        position: 'relative',
                        marginLeft: '-24px',
                        marginRight: '-24px',
                    }}
                >
                    <Image
                        src={wayForPayLogo}
                        sx={{
                            width: '320px',
                        }}
                    />
                </Stack>
                <Stack gap="25px">
                    <TextField
                        placeholder="Merchant login"
                        variant="outlined"
                        fullWidth
                        name="payload.merchant_login"
                        value={formik.values.payload.merchant_login}
                        onChange={formik.handleChange}
                        error={
                            formik.touched?.payload?.merchant_login &&
                            Boolean(formik.errors?.payload?.merchant_login)
                        }
                        helperText={
                            formik.touched?.payload?.merchant_login &&
                            formik.errors?.payload?.merchant_login
                        }
                    />
                    <TextField
                        placeholder="Merchant secret key"
                        variant="outlined"
                        fullWidth
                        name="payload.merchant_secret_key"
                        value={formik.values.payload.merchant_secret_key}
                        onChange={formik.handleChange}
                        error={
                            formik.touched?.payload?.merchant_secret_key &&
                            Boolean(formik.errors?.payload?.merchant_secret_key)
                        }
                        helperText={
                            formik.touched?.payload?.merchant_secret_key &&
                            formik.errors?.payload?.merchant_secret_key
                        }
                    />
                </Stack>
                <Stack gap="10px">
                    <LoadingButton variant="contained" type="submit">
                        {t('backoffice:settings.payment.forms.save')}
                    </LoadingButton>
                    <Button variant="outlined">
                        {t('backoffice:settings.payment.forms.cancel')}
                    </Button>
                </Stack>
            </Stack>
        </form>
    )
}
export default WayForPayForm

import { FetchResult, useMutation } from '@apollo/client'

import {
    LessonCreateDocument,
    LessonCreateMutation,
    LessonDeleteDocument,
    LessonDeleteMutation,
    LessonUpdateDocument,
    LessonUpdateMutation,
    MutationLessonCreateArgs,
    MutationLessonDeleteArgs,
    MutationLessonUpdateArgs,
} from 'api/root/generated'

export const useLessonsAPI = () => {
    const [updateLesson, { loading: updateLoading }] =
        useMutation(LessonUpdateDocument)
    const [createLesson, { loading: createLoading }] =
        useMutation(LessonCreateDocument)
    const [deleteLesson, { loading: deleteLoading }] =
        useMutation(LessonDeleteDocument)

    const update = (data: MutationLessonUpdateArgs) => {
        return updateLesson({ variables: data }).then(
            (response: FetchResult<LessonUpdateMutation>) => {
                return response?.data?.lessonUpdate
            }
        )
    }

    const create = (data: MutationLessonCreateArgs) => {
        return createLesson({ variables: data }).then(
            (response: FetchResult<LessonCreateMutation>) => {
                return response?.data?.lessonCreate
            }
        )
    }

    const remove = (data: MutationLessonDeleteArgs) => {
        return deleteLesson({ variables: data }).then(
            (response: FetchResult<LessonDeleteMutation>) => {
                return response?.data?.lessonDelete
            }
        )
    }

    return {
        update,
        create,
        remove,
        loading: createLoading || updateLoading || deleteLoading,
    }
}

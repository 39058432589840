import React, { JSX } from 'react'

import { Stack, Typography } from '@mui/material'

interface LayoutProps {
    title: string
    children: JSX.Element
    icon?: JSX.Element
}

const Layout = ({ title, children, icon }: LayoutProps) => {
    return (
        <Stack
            gap="25px"
            sx={{
                padding: '15px 25px 25px',
                borderRadius: '6px',
                border: '1px solid rgba(255, 255, 255, 0.12)',
            }}
        >
            <Stack gap="10px" direction="row" alignItems="center">
                <Typography fontSize="30px">{title}</Typography>
                {icon && icon}
            </Stack>
            {children}
        </Stack>
    )
}

export default Layout

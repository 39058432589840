import React, { JSX } from 'react'

import { Box } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { ForgotPasswordForm } from '../../forms'
import { paths } from '@auth/index.routes'
import { useDocumentTitle } from '../../../../../hooks/common'

const ForgotPasswordPage = (): JSX.Element => {
    useDocumentTitle(t('auth:pageTitles.forgotPassword'))

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const redirectTo = searchParams.get('redirectTo')
    return (
        <Box
            sx={{
                width: '100%',
                padding: '25px',
            }}
        >
            <ForgotPasswordForm
                redirectTo={redirectTo}
                onSuccess={() => navigate(`../${paths.emailVerification}`)}
            />
        </Box>
    )
}
export default ForgotPasswordPage

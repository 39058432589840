import { useEffect, useState } from 'react'

import { FetchResult, useMutation, useQuery } from '@apollo/client'

import {
    CourseListDocument,
    CourseType,
    CourseDocument,
    CourseCreateDocument,
    CourseCreateMutation,
    MutationCourseCreateArgs,
    CourseUpdateDocument,
    CourseUpdateMutation,
    MutationCourseUpdateArgs,
    CourseDeleteDocument,
    MutationCourseDeleteArgs,
    CourseDeleteMutation,
} from 'api/root/generated'

export const useCourses = (skip = false) => {
    const [response, setResponse] = useState<CourseType[]>([])

    const { data, loading, error, refetch } = useQuery(CourseListDocument, {
        skip,
    })

    useEffect(() => {
        if (data?.courseList) {
            const courses: CourseType[] = data?.courseList.edges.map(
                edge => edge?.node as CourseType
            )
            setResponse(courses)
        }
    }, [data])

    return {
        courses: response,
        loading,
        error,
        refetch,
    }
}

export const useCourse = (id?: string) => {
    const { data, loading, error, refetch } = useQuery(CourseDocument, {
        ...(id && {
            variables: {
                id,
            },
        }),
        skip: !id,
    })

    let course

    if (data?.course) {
        course = data.course
    }

    return {
        course,
        loading: loading || typeof data === 'undefined',
        error,
        refetch,
    }
}

export const useCoursesAPI = () => {
    const [updateCourse, { loading: updating }] =
        useMutation(CourseUpdateDocument)
    const [createCourse, { loading: creating }] =
        useMutation(CourseCreateDocument)
    const [deleteCourse, { loading: deleting }] =
        useMutation(CourseDeleteDocument)

    const update = (data: MutationCourseUpdateArgs) => {
        return updateCourse({ variables: data }).then(
            (response: FetchResult<CourseUpdateMutation>) => {
                return response?.data?.courseUpdate
            }
        )
    }

    const create = (data: MutationCourseCreateArgs) => {
        return createCourse({ variables: data }).then(
            (response: FetchResult<CourseCreateMutation>) => {
                return response?.data?.courseCreate
            }
        )
    }

    const remove = (data: MutationCourseDeleteArgs, userId: string) => {
        return deleteCourse({
            variables: data,
            refetchQueries: [
                {
                    query: CourseListDocument,
                    variables: {
                        userId,
                    },
                },
            ],
        }).then((response: FetchResult<CourseDeleteMutation>) => {
            return response?.data?.courseDelete
        })
    }

    return {
        loading: creating || updating || deleting,
        update,
        create,
        remove,
    }
}

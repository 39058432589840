import { createTheme } from '@mui/material/styles'

const palette = {
    primary: {
        main: '#cbacf7',
        light: '#e2d5f6',
        dark: '#6e5e86',
    },
    secondary: {
        main: '#f0f0f0',
        dark: '#3a3a3a',
        light: '#706f6f',
    },
    error: { main: '#ff7979' },
    success: { main: '#43cc5a' },
}

const theme = createTheme({
    palette: {
        ...palette,
        mode: 'dark',
    },
    breakpoints: {
        values: {
            xs: 432,
            sm: 834,
            md: 1340,
            lg: 1500,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: 'Roboto',
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    '& .MuiInputLabel-root': {
                        color: 'white',
                        backgroundColor: '#181818',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#6e6e6e',
                    },
                    '& .MuiInputBase-input': {
                        color: 'white',
                    },
                    '& .MuiButtonBase-root': {
                        color: '#6e6e6e',
                    },
                    '& .MuiFormHelperText-root': {
                        '&.Mui-error': {
                            background: '#ad4545',
                            color: 'white',
                            padding: '5px',
                            fontSize: '14px',
                            width: '100%',
                            top: '5px',
                            zIndex: 2,
                            position: 'relative',
                        },
                    },
                },
            },
            defaultProps: {
                variant: 'standard',
                fullWidth: true,
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    bottom: '-25px',
                    left: 0,
                },
            },
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    textTransform: 'none',
                },
                textPrimary: {
                    textTransform: 'none',
                },
            },
            defaultProps: {
                variant: 'contained',
                disableRipple: true,
                disableFocusRipple: true,
                disableElevation: true,
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                },
            },
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    '& .MuiAutocomplete-listbox': {
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    textDecoration: 'none',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                color: 'secondary',
            },
        },

        MuiFormControl: {
            styleOverrides: {
                root: {},
            },
            defaultProps: {
                variant: 'standard',
            },
        },
    },
})

export default theme

import React, { JSX } from 'react'

import { Typography, Stack, Box, useMediaQuery } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { AddCircleOutline, QuestionMarkOutlined } from '@mui/icons-material'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

import {
    CourseForm,
    LessonForm,
    ModuleForm,
} from '@backoffice/components/forms'
import { Image } from 'components'
import { useDialog, useDocumentTitle } from 'hooks/common'
import { paths as backofficePaths } from '@backoffice/index.routes'
import { paths as storefrontPaths } from '@storefront/index.routes'
import { useCourse, useCoursesAPI } from 'hooks/courses'
import { CourseType, LessonType, ModuleType } from 'api/root/generated'
import { useModulesAPI } from '@backoffice/hooks/modules'
import { useLessonsAPI } from '@backoffice/hooks/lessons'
import Module from './components/Module'
import Actions from './components/Actions'
import useConfirmationDialog from '@backoffice/hooks/common/useConfirmationDialog'

const CoursePage = (): JSX.Element => {
    const matches1400px = useMediaQuery('(max-width:1400px)')
    const matches1200px = useMediaQuery('(max-width:1200px)')
    const matches800px = useMediaQuery('(max-width:800px)')

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { courseId } = useParams()

    const { course, loading, refetch, error } = useCourse(courseId as string)

    useDocumentTitle(
        `${t('backoffice:pageTitles.backoffice')}| ${course?.name}`
    )

    const { remove } = useModulesAPI()
    const { remove: removeCourse } = useCoursesAPI()
    const { remove: removeLesson } = useLessonsAPI()

    const { open, close } = useDialog()

    const { requestConfirmation } = useConfirmationDialog<
        ModuleType | LessonType | CourseType
    >()

    const handleOpenModuleForm = (course: CourseType, module?: ModuleType) => {
        open({
            component: ModuleForm,
            props: {
                module,
                course,
                onSuccess: (action: 'add' | 'update') => {
                    close()
                    refetch()

                    const message =
                        action === 'add'
                            ? t(
                                  'backoffice:content.course.module.notifications.successCreated'
                              )
                            : t(
                                  'backoffice:content.course.module.notifications.successUpdated'
                              )

                    enqueueSnackbar(message, { variant: 'success' })
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleOpenLessonForm = (module: ModuleType, lesson?: LessonType) => {
        open({
            component: LessonForm,
            props: {
                lesson,
                module,
                onSuccess: (action: 'add' | 'update') => {
                    refetch()
                    close()

                    const message =
                        action === 'add'
                            ? t(
                                  'backoffice:content.course.lesson.notifications.successCreated'
                              )
                            : t(
                                  'backoffice:content.course.lesson.notifications.successUpdated'
                              )

                    enqueueSnackbar(message, { variant: 'success' })
                },
            },
            options: {
                onClose: close,
            },
        })
    }

    const handleOpenLesson = (lesson: LessonType) => {
        navigate(`../${storefrontPaths.watch(lesson.id)}`)
    }

    const handleDeleteLesson = (item: LessonType) => {
        removeLesson({
            input: {
                lessonId: item.id,
            },
        }).then(response => {
            if (response?.success) {
                refetch()
                close()
                enqueueSnackbar(
                    t(
                        'backoffice:content.course.lesson.notifications.successDeleted'
                    ),
                    { variant: 'success' }
                )
            }
        })
    }

    const handleDeleteModule = (item: ModuleType) => {
        remove({
            input: {
                moduleId: item.id,
            },
        }).then(response => {
            if (response?.success) {
                refetch()
                close()
                enqueueSnackbar(
                    t(
                        'backoffice:content.course.module.notifications.successDeleted'
                    ),
                    { variant: 'success' }
                )
            }
        })
    }

    const handleDeleteCourse = () => {
        removeCourse(
            {
                input: {
                    courseId: course?.id as string,
                },
            },
            course?.coach?.id as string
        ).then(response => {
            if (response?.success) {
                close()
                enqueueSnackbar(
                    t('backoffice:content.course.notifications.successDeleted'),
                    { variant: 'success' }
                )
                navigate(`../${backofficePaths.content}`)
            }
        })
    }

    const handleOpenCourseForm = () => {
        open({
            component: CourseForm,
            props: {
                onSuccess: (action: 'add' | 'update') => {
                    close()
                    refetch()

                    const message =
                        action === 'add'
                            ? t(
                                  'backoffice:content.course.notifications.successCreated'
                              )
                            : t(
                                  'backoffice:content.course.notifications.successUpdated'
                              )

                    enqueueSnackbar(message, { variant: 'success' })
                },
                onDelete: () => {
                    close()
                    requestConfirmation(
                        course as CourseType,
                        handleDeleteCourse
                    )
                },
                course,
            },
            options: {
                onClose: close,
            },
        })
    }

    const renderCreateNewModuleButton = () => {
        return (
            <Stack
                onClick={() => {
                    handleOpenModuleForm(course as CourseType)
                }}
                alignItems="center"
                direction="row"
                gap="10px"
                sx={theme => ({
                    cursor: 'pointer',
                    padding: matches800px ? '10px 15px' : '15px 25px',
                    marginBottom: '25px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
                    border: '2px solid #222',
                    '&:hover': {
                        border: `2px solid${theme.palette.primary.main}`,
                    },
                })}
            >
                <AddCircleOutline
                    sx={{
                        color: 'primary.main',
                        fontSize: matches800px ? '32px' : '42px',
                    }}
                />
                <Typography
                    fontSize={matches800px ? '18px' : '22px'}
                    lineHeight="23px"
                    sx={{
                        textShadow: '3px 3px 3px #000',
                        letterSpacing: '-1px',
                        transition: '.2s ease',
                        padding: '5px 10px',
                        background:
                            'linear-gradient(90deg, rgba(24,24,24,0.7) 0%, rgba(24,24,24,0) 100%)',
                    }}
                >
                    {course?.modules?.edges.length === 0
                        ? t(
                              'backoffice:content.course.module.actions.createFirst'
                          )
                        : t('backoffice:content.course.module.actions.create')}
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack gap="25px" padding={matches1200px ? '10px' : '1% 2%'}>
            <Stack
                gap={matches1400px ? '15px' : 0}
                direction="column"
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={matches800px ? '10px' : '25px'}
                >
                    {course?.appearance?.background?.img150x100 ? (
                        <Image
                            src={
                                course?.appearance?.background
                                    ?.img150x100 as string
                            }
                            sx={{
                                width: '100px',
                                height: '60px',
                                ...(matches800px && {
                                    width: '60px',
                                    height: '40px',
                                }),
                                objectFit: 'cover',
                                boxShadow: '0 0 7px #000',
                                border: `2px solid transparent`,
                            }}
                        />
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            sx={{
                                width: '100px',
                                height: '60px',
                                ...(matches800px && {
                                    width: '60px',
                                    height: '40px',
                                }),
                                objectFit: 'cover',
                                boxShadow: '0 0 7px #000',
                                border: `2px solid transparent`,
                            }}
                        >
                            <QuestionMarkOutlined
                                sx={{
                                    color: '#4e4e4e',
                                    fontSize: '45px',
                                }}
                            />
                        </Stack>
                    )}
                    <Typography fontSize="max(min(2vw, 32px), 17px)">
                        {course?.name}
                    </Typography>
                </Stack>
                <Actions
                    onStream={() =>
                        navigate(
                            `../${backofficePaths.courseStream(
                                courseId as string
                            )}`
                        )
                    }
                    onAccess={() =>
                        navigate(
                            `../${backofficePaths.courseAccess(
                                courseId as string
                            )}`
                        )
                    }
                    onEdit={handleOpenCourseForm}
                />
                {/* <Stack */}
                {/*    direction="row" */}
                {/*    alignItems="center" */}
                {/*    gap="15px" */}
                {/*    sx={{ */}
                {/*        alignSelf: 'flex-end', */}
                {/*        '& .MuiButton-root': { */}
                {/*            fontSize: '16px', */}
                {/*        }, */}
                {/*        '& .MuiSvgIcon-root': { */}
                {/*            fontSize: matches800px */}
                {/*                ? '18px' */}
                {/*                : matches1200px */}
                {/*                ? '22px' */}
                {/*                : '28px', */}
                {/*        }, */}
                {/*    }} */}
                {/* > */}
                {/*    {matches700px ? ( */}
                {/*        <IconButton */}
                {/*            color="info" */}
                {/*            sx={theme => ({ */}
                {/*                border: `1px solid #29b6f6`, */}
                {/*                borderRadius: '4px', */}
                {/*            })} */}
                {/*            onClick={() => */}
                {/*                navigate( */}
                {/*                    `../${backofficePaths.courseAccess( */}
                {/*                        courseId as string */}
                {/*                    )}` */}
                {/*                ) */}
                {/*            } */}
                {/*        > */}
                {/*            <PublicRounded /> */}
                {/*        </IconButton> */}
                {/*    ) : ( */}
                {/*        <Button */}
                {/*            variant="outlined" */}
                {/*            color="info" */}
                {/*            startIcon={<PublicRounded />} */}
                {/*            onClick={() => */}
                {/*                navigate( */}
                {/*                    `../${backofficePaths.courseAccess( */}
                {/*                        courseId as string */}
                {/*                    )}` */}
                {/*                ) */}
                {/*            } */}
                {/*        > */}
                {/*            {t('backoffice:course.grantAccess')} */}
                {/*        </Button> */}
                {/*    )} */}
                {/*    {matches700px ? ( */}
                {/*        <IconButton */}
                {/*            color="warning" */}
                {/*            sx={theme => ({ */}
                {/*                border: `1px solid #ffa726`, */}
                {/*                borderRadius: '4px', */}
                {/*            })} */}
                {/*            onClick={handleOpenCourseForm} */}
                {/*        > */}
                {/*            <EditOutlined /> */}
                {/*        </IconButton> */}
                {/*    ) : ( */}
                {/*        <Button */}
                {/*            variant="outlined" */}
                {/*            color="warning" */}
                {/*            startIcon={<EditOutlined />} */}
                {/*            onClick={handleOpenCourseForm} */}
                {/*        > */}
                {/*            {t('courseManageActions.editTitle')} */}
                {/*        </Button> */}
                {/*    )} */}
                {/* </Stack> */}
            </Stack>

            <Box
                sx={{
                    height: '2px',
                    position: 'relative',
                    zIndex: 1,
                    background:
                        'linear-gradient(90deg, rgba(228,217,217,0) 10%, rgba(163,163,163,1) 100%)',
                }}
            />
            <Box>
                {course && renderCreateNewModuleButton()}
                {course?.modules?.edges
                    .map(e => e?.node as ModuleType)
                    .map(m => (
                        <Module
                            module={m}
                            key={m?.id}
                            onOpenLesson={handleOpenLesson}
                            onNewLesson={handleOpenLessonForm}
                            onEditLesson={handleOpenLessonForm}
                            onEditModule={handleOpenModuleForm}
                            onDeleteLesson={lesson => {
                                requestConfirmation(lesson, () =>
                                    handleDeleteLesson(lesson)
                                )
                            }}
                            onDeleteModule={module => {
                                requestConfirmation(module, () =>
                                    handleDeleteModule(module)
                                )
                            }}
                        />
                    ))}
            </Box>
        </Stack>
    )
}
export default CoursePage

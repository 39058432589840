import React, { forwardRef } from 'react'

import { CustomContentProps, VariantType, useSnackbar } from 'notistack'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import {
    CheckCircleOutline,
    Close,
    InfoOutlined,
    ErrorOutline,
} from '@mui/icons-material'
import { keyframes } from '@emotion/react'
import { Theme } from '@mui/material/styles'

const expanding = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`

const Toast = forwardRef<HTMLDivElement, CustomContentProps>(
    (props: CustomContentProps, ref) => {
        const { closeSnackbar } = useSnackbar()

        const { variant, message, autoHideDuration, id } = props
        const resolveIcon = (variant: VariantType) => {
            switch (variant) {
                case 'success':
                    return (
                        <CheckCircleOutline
                            sx={theme => ({
                                color: theme.palette.success.main,
                            })}
                        />
                    )
                case 'error':
                    return (
                        <ErrorOutline
                            sx={theme => ({ color: theme.palette.error.main })}
                        />
                    )
                default:
                    return (
                        <InfoOutlined
                            sx={theme => ({
                                color: theme.palette.secondary.main,
                            })}
                        />
                    )
            }
        }

        return (
            <Stack
                ref={ref}
                direction="row"
                gap="25px"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    overflow: 'hidden',
                    padding: '15px 15px 16px',
                    backgroundColor: '#181818',
                    border: '1px solid rgb(135 133 133 / 50%)',
                    borderRadius: '4px',
                    position: 'relative',
                }}
            >
                <Stack gap="15px" direction="row">
                    {resolveIcon(variant)}
                    <Typography>{message}</Typography>
                </Stack>
                <IconButton onClick={() => closeSnackbar(id)}>
                    <Close fontSize="small" color="disabled" />
                </IconButton>
                <Stack
                    sx={theme => ({
                        position: 'absolute',
                        opacity: '0.7',
                        bottom: '0',
                        left: '1px',
                        height: '2px',
                        background:
                            variant === 'success'
                                ? theme.palette.success.main
                                : variant === 'error'
                                ? theme.palette.error.main
                                : theme.palette.secondary.main,
                        animation: `${expanding} ${
                            (autoHideDuration || 3000) / 1000
                        }s ease-in-out forwards`,
                    })}
                />
            </Stack>
        )
    }
)

export default Toast
